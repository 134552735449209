import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CredentialsService } from 'src/app/authentication/credentials/credentials.service';
import { CartService } from 'src/services/cart/cart.service';
import { CartProduct, Product } from 'src/services/classes/models/product';
import { NotificationsService } from 'src/services/classes/notifications/notifications.service';
import { Logger } from 'src/services/core/logger/logger.service';
import { ProductsService } from 'src/services/products/products.service';

const log = new Logger('Cart-Product');
@Component({
  selector: 'app-cart-product',
  templateUrl: './cart-product.component.html',
  styleUrls: ['./cart-product.component.scss']
})
export class CartProductComponent implements OnInit {
  @Input() forCart = false;
  @Input() product: CartProduct;
  @Input() selectedProductIndex: number;

  @Output() productToRemove = new EventEmitter<CartProduct>();
  count = 1;
  selectedSize: string;
  constructor(
    private productsService: ProductsService,
    private credentialsService: CredentialsService,
    private cartService: CartService,
    private notifyService: NotificationsService
  ) {}

  ngOnInit() {}

  selectSize(e: any, product: CartProduct) {
    product.size = e.target.value;
    if (this.credentialsService.isAuthenticated() === true) {
      this.updateProduct(product, 'sizeChange');
    } else {
      log.debug('product-size-update: ', product, product.size);
      this.cartService.setProductSize(product, product.size);
      this.notifyService.publishMessages(
        'Product in cart updated',
        'success',
        1
      );
    }
  }

  reduceCount(product: CartProduct) {
    if (product.quantity_ordered > 1) {
      product.quantity_ordered--;

      if (this.credentialsService.isAuthenticated() === true) {
        this.updateProduct(product, 'decrease');
      } else {
        this.cartService.reduceQuantity(product, 1);
        this.notifyService.publishMessages(
          'Product in cart updated',
          'success',
          1
        );
      }
    } else {
      return;
    }
  }

  increaseCount(product: CartProduct) {
    // this is basically the same as "addToCart" locally since the cartService
    // would increase the quantity automatically if the item already exists in cart
    // So we will call the cartService's addToCart to increase quantoty of a
    // product (in auth-ed and un-auth-ed cases).
    product.quantity_ordered++;

    if (this.credentialsService.isAuthenticated() === true) {
      this.updateProduct(product, 'increase');
    } else {
      this.cartService.addToCart(product, 1);
      this.notifyService.publishMessages(
        'Product in cart updated',
        'success',
        1
      );
    }
  }

  extractColorsAndSizes(product: Product) {
    let sizes = [];
    let availableColors = [];
    if (product.product_variation) {
      sizes = product.product_variation.map((variation: any) => {
        if (variation.product_size) {
          return variation.product_size.size;
        } else {
          return variation.size;
        }
      });
      availableColors = product.product_variation.map((variation: any) => {
        if (variation.product_color) {
          return variation.product_color.color;
        } else {
          return variation.color;
        }
      });
    }
    return {
      sizes,
      availableColors
    };
  }

  // Increase or reduce quantity of product in cart
  updateProduct(product: CartProduct, updateType: string) {
    const { product_id, cartId, quantity_ordered, unit_price } = product;
    let payload;

    if (updateType === 'increase') {
      payload = {
        product_id,
        price: parseFloat(String(unit_price)),
        quantity: quantity_ordered
      };
    } else if (updateType === 'decrease') {
      payload = {
        product_id,
        price: parseFloat(String(unit_price)),
        quantity: quantity_ordered
      };
    } else if (updateType === 'sizeChange') {
      payload = {
        product_id,
        price: parseFloat(String(unit_price)),
        quantity: quantity_ordered,
        size: product.size
      };
    }

    this.updateProductInCart(cartId, payload, updateType);
  }

  updateProductInCart(cartId: any, payload: any, updateType?: string) {
    const updateProductInCart$ = this.productsService.updateCart(
      cartId,
      payload
    );
    updateProductInCart$.subscribe(
      (res: any) => {
        if (res.error === false) {
          if (res.data && res.data !== true) {
            const cartItem = res.data;
            if (cartItem.product !== null) {
              if (cartItem.product.product_image.includes('|')) {
                cartItem.product.product_image = cartItem.product.product_image.split(
                  '|'
                );
              } else {
                cartItem.product.product_image = cartItem.product.product_image;
              }

              const colorsAndSizes = this.extractColorsAndSizes(
                cartItem.product
              );
              cartItem.product.cartId = cartItem.id;
              cartItem.product.selectedPrice = cartItem.price;
              cartItem.product.size = cartItem.size;
              cartItem.product.color = cartItem.color;
              cartItem.product.quantity_ordered = cartItem.quantity;
              cartItem.product.product_name = cartItem.product.product_name;
              cartItem.product.brand_name = cartItem.product.brand.brand_name;

              if (updateType === 'increase') {
                this.cartService.addToCart(
                  { ...cartItem.product, ...colorsAndSizes },
                  1
                );
              } else if (updateType === 'decrease') {
                log.debug('decrease!', cartItem.product.quantity_ordered);
                this.cartService.addToCart(
                  { ...cartItem.product, ...colorsAndSizes },
                  -1
                );
              } else if (updateType === 'sizeChange') {
                log.debug('sizeChange!', cartItem.product.size);
                this.cartService.setProductSize(
                  { ...cartItem.product, ...colorsAndSizes },
                  payload.size
                );
              }
              this.notifyService.publishMessages(res.message, 'success', 1);
            }
          }
        } else {
          this.notifyService.publishMessages(res.message, 'danger', 1);
        }
      },
      error => log.debug('error: ', error)
    );
  }

  removeFromCart(product: CartProduct) {
    if (product.cartId && this.credentialsService.isAuthenticated() === true) {
      // Use API here and in response, remove with cartService as well
      const deleteFromCart$ = this.productsService.deleteCartItem(
        product.cartId
      );
      deleteFromCart$.subscribe(
        (res: any) => {
          if (res.error === false) {
            // log.debug('res: ', res);
            this.cartService.removeFromCart(this.selectedProductIndex);
            if (res.message) {
              this.notifyService.publishMessages(res.message, 'success', 1);
            }
          } else {
            this.notifyService.publishMessages(res.message, 'danger', 1);
          }
        },
        (error: any) => log.debug('error: ', error)
      );
    } else {
      this.cartService.removeFromCart(this.selectedProductIndex);
      this.notifyService.publishMessages(
        'Product removed from cart',
        'success',
        1
      );
    }
  }
}
