<div
  *ngIf="status"
  style="position: fixed; z-index: 10000; height: 100vh; width: 100vw"
  class="dark-overlay main"
>
  <div style="z-index: 22" class="col-lg-6 col-lg-offset-3">
    <div class="card border-0 bg-transparent">
      <div class="card-body">
        <div class="d-flex justify-content-center mt-n5">
          <div class="avatar-lg text-primary text-center" role="status">
            <h3
              style="
                color: #000000 !important;
                margin-bottom: 10px;
                margin-top: 60px;
              "
              class="font-weight-medium mb-4"
            >
              <img
                id="ldr-img"
                style="width: 100%; height: 11rem"
                src="{{ organizationLogo }}"
                alt=""
                class="mt-3 fadeIn ldr-img"
              />
              {{ loaderTxt }}....
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
