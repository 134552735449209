import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Options, LabelType } from '@angular-slider/ngx-slider';
import { HomeService } from 'src/services/home/home.service';
import { Logger } from 'src/services/core/logger/logger.service';
import { element } from 'protractor';

const log = new Logger('filters');
@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {
  @ViewChild('dropdown', {static: false}) dropdown: ElementRef;
  isOpen = false;
  @Output() emitSelctedSizes = new EventEmitter();
  @Output() emitSelctedColours = new EventEmitter();
  @Output() emitSelctedBrands = new EventEmitter();
  @Output() emitFeatured = new EventEmitter();
  @Output() emitNewest = new EventEmitter();
  @Output() emitPrice_low_high = new EventEmitter();
  @Output() emitPrice_high_low = new EventEmitter();
  @Output() emitLowPrice = new EventEmitter();
  @Output() emitHighPrice = new EventEmitter();
  @Output() emitSelctedCategories = new EventEmitter();
  @Output() getAllProducts = new EventEmitter();

  lowPrice: number = 0
  highPrice: number = 1000000
  isDrawerOpen = false;
  mobiledrawer: boolean = false;
  minValue: number = 0;
  maxValue: number = 1000000;
  innerWidth: any;
  sizes: any = [];
  colors: any = [];
  categories: any = [];
 
  options: Options = {
    floor: 0,
    ceil: 1000000,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          this.lowPrice = value
         this.emitLowPrice.emit(value)

          return '₦' + value;
        case LabelType.High:
          this.highPrice = value
         this.emitHighPrice.emit(value)

          return '₦' + value;

        default:
          return '₦' + value;
      }
    }
  };


  selctedCategories: any = []
  selctedSizes: any = [];
  selctedColours: any = []
  filters: any = [];
  selctedBrands: any = []
  selectedColor: string;
 
  currentSort = 'Sort By' 
  constructor(private homeService: HomeService) {}

  ngOnInit() {
    this.getColors();
    this.getSizes();
    this.getCategories();
  }


  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }
  @HostListener('document:click', ['$event.target'])
  onClick(targetElement: any) {
    const clickedInside = this.dropdown.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.isOpen = false;
    }
  }

  clostFilter(){
    document.getElementById('dropdownMenuButton1').click()
  }

  setSortbyFilter(value){
    if(value === 'featured'){
      this.currentSort = value
      this.emitFeatured.emit(true)
      this.emitNewest.emit(false)
      this.emitPrice_high_low.emit(false)
      this.emitPrice_low_high.emit(false)
      this.filterAllProducts();


    }else if(value === 'newest'){
      this.currentSort = value

      this.emitNewest.emit(true)
      this.emitFeatured.emit(false)
      this.emitPrice_high_low.emit(false)
      this.emitPrice_low_high.emit(false)
    this.filterAllProducts();
    


    }else if(value === 'Price High - Low'){
      this.currentSort = value

      this.emitPrice_high_low.emit(true)
      this.emitNewest.emit(false)
      this.emitFeatured.emit(false)
      this.emitPrice_low_high.emit(false)

    this.filterAllProducts();

    }else if(value === 'Low - Price High'){
      this.currentSort = value

      this.emitPrice_low_high.emit(true)
      this.emitPrice_high_low.emit(false)
      this.emitNewest.emit(false)
      this.emitFeatured.emit(false)
      this.filterAllProducts();

    }
  }

  filterAllProducts() {
    this.getAllProducts.emit('filterProducts');
    this.closeDrawer('close-drawer')

  }

  setLowest(event){
    this.lowPrice = event.target.value
    log.debug("Lowest price",this.lowPrice)
    this.emitLowPrice.emit(this.lowPrice)
  }
  setHighest(event){
    this.highPrice = event.target.value
    log.debug("highest price",this.highPrice)
    this.emitHighPrice.emit(this.highPrice)

  }

  resetPrice(){
    this.emitLowPrice.emit(100)
    this.emitHighPrice.emit(1000000)
    this.minValue= 100;
    this.lowPrice = 100
    this.highPrice = 1000000
    this.maxValue = 1000000;
    this.filterAllProducts()
  }



  onCheckboxChange(event, id) {
    // this.filters.push(cat)
    const currentValue = event.target.value;
    const exist = this.selctedCategories.includes(id);
    if (!exist) {
      this.selctedCategories.push(id);
    } else {
      this.selctedCategories = this.selctedCategories.filter(
        element => element !== id
      );
    }
    this.emitSelctedCategories.emit(this.selctedCategories)
    this.filterAllProducts();
    log.debug('categories', this.selctedCategories);
  }

  resetCategory() {
    this.selctedCategories = []
    this.emitSelctedCategories.emit(this.selctedCategories)
    this.filterAllProducts();
  }
  resetColor() {
    this.selctedColours = [];
    this.emitSelctedColours.emit(this.selctedColours)
    this.filterAllProducts();
  }
  resetSize() {
    this.selctedSizes = [];
    this.emitSelctedSizes.emit(this.selctedSizes)
    this.filterAllProducts();
  }

  selectColor(id) {
    const exist = this.selctedColours.includes(id);
    if (!exist) {
      this.selctedColours.push(id);
    } else {
      this.selctedColours = this.selctedColours.filter(
        element => element !== id
      );
    }
    log.debug('colors', this.selctedColours);
    this.emitSelctedColours.emit(this.selctedColours)
    this.filterAllProducts();
  }

  selectSize(id) {
    const exist = this.selctedSizes.includes(id);
    if (exist === false) {
      this.selctedSizes.push(id);
    } else {
      this.selctedSizes = this.selctedSizes.filter(element => element !== id);
      // log.debug('filter', filter)
    }
    this.emitSelctedSizes.emit(this.selctedSizes)
    this.filterAllProducts();

    log.debug('sizes', this.selctedSizes);
  }

  handleColorSelection(color: string) {
    this.selectedColor = color.toLowerCase();
  }
  getColors() {
    this.homeService.getAllColors().subscribe((res: any) => {
      if (res.error == false) {
        log.debug('colors', res.data);
        this.colors = res.data;
      }
    });
  }
  getSizes() {
    this.homeService.getAllSizes().subscribe((res: any) => {
      if (res.error == false) {
        log.debug('sizes', res.data);
        this.sizes = res.data;
      }
    });
  }
  getCategories() {
    this.homeService.getCategories().subscribe((res: any) => {
      if (res.error == false) {
        log.debug('categories', res.data);
        this.categories = res.data;
      }
    });
  }


  showMobileFilter() {
    this.isDrawerOpen = true;
  }


  closeDrawer(event: string) {
    if (event === 'close-drawer') {
      this.isDrawerOpen = false;
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth > 995) {
      this.isDrawerOpen = false;
      this.mobiledrawer = false;
    } else {
      this.mobiledrawer = true;
    }
    // if (this.innerWidth < 995) {
    //   this.dropped = false;
    // }
  }
}
