import { finalize } from 'rxjs/operators';
import { Logger } from 'src/services/core/logger/logger.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CartProduct, Product } from 'src/services/classes/models/product';
import { CartService } from 'src/services/cart/cart.service';
import { Router } from '@angular/router';
import { CredentialsService } from 'src/app/authentication/credentials/credentials.service';
import { NotificationsService } from 'src/services/classes/notifications/notifications.service';
import { ProductsService } from 'src/services/products/products.service';

const log = new Logger('product-card');
@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
})
export class ProductComponent implements OnInit {
  @Input() product: Product;
  selectedVariant: any;
  isLoading = false;
  isArray = Array.isArray;
  activeSlide = 0;
  wishlistIDs: any[] = [];
  wishlistProducts: any[] = [];

  constructor(
    private cartService: CartService,
    private router: Router,
    private credentialsService: CredentialsService,
    private notifyService: NotificationsService,
    private productsService: ProductsService
  ) {}

  ngOnInit() {
    this.setUpFaves();
  }

  goToProductDetails(product: Product) {
    this.router.navigateByUrl(`/products/${product.id}`);
  }

  handleProductToCartAddition(product: Product) {
    this.addToCart(product);
  }

  handleProductToWishlistAddition(product: Product) {
    this.addToWishlist(product);
  }

  handleColorSelection(product: any) {
    this.selectedVariant = {
      color: product.product_color.color.toLowerCase(),
      size: product.product_size.size,
      price: product.price,
    };
  }

  setUpFaves() {
    if (this.credentialsService.isAuthenticated()) {
      const _wishlist = JSON.parse(sessionStorage.getItem('wishList')) || [];
      this.wishlistProducts = _wishlist;
      this.wishlistIDs =
        _wishlist.length > 0
          ? _wishlist.map((product: any) => product.product_id)
          : [];
    }
  }

  processPayloadForCartAndWishlist(product: Product) {
    /**
     * {
     *  "product_id": 1,
     *  "price": 4000,
     *  "quantity": "3",
     *  "color": "red",
     *  "size": "Small"
     * }
     */

    /**
     * From observation, it looks like users can select a color off the product card
     * but they cannot select size, obviously, I'll dig into product_variation to pick
     * out the first size variable I can find.
     * For now, I will pick teh first color as well, till I figure out how to handle the colors
     * selection on the card itself - or confirm the selection has to be done.
     */
    // A little bit of payload generation
    const { id, product_variation } = product;
    let color = '';
    let size = '';
    let variantPrice = '';
    if (product_variation && product_variation.length > 0) {
      const { product_color, price, product_size } = product_variation[0];

      if (product_color && product_color.color !== null) {
        color =
          this.selectedVariant && this.selectedVariant.color
            ? this.selectedVariant.color
            : product_color.color.toLowerCase();
      } else {
        color =
          this.selectedVariant && this.selectedVariant.color
            ? this.selectedVariant.color
            : product_variation[0].color.toLowerCase();
      }
      if (product_size && product_size.size !== null) {
        size =
          this.selectedVariant && this.selectedVariant.size
            ? this.selectedVariant.size
            : product_size.size;
      } else {
        size =
          this.selectedVariant && this.selectedVariant.size
            ? this.selectedVariant.size
            : product_variation[0].size;
      }
      variantPrice =
        this.selectedVariant && this.selectedVariant.price
          ? this.selectedVariant.price
          : price;
    }

    const payload = {
      product_id: id,
      price: variantPrice,
      quantity: 1,
      color,
      size,
    };

    return payload;
  }

  extractColorsAndSizes(product: Product) {
    let sizes = [];
    let availableColors = [];
    if (product.product_variation) {
      sizes = product.product_variation.map((variation: any) => {
        if (variation.product_size) {
          return variation.product_size.size;
        }
      });
      availableColors = product.product_variation.map((variation: any) => {
        if (variation.product_color) {
          return variation.product_color.color;
        }
      });
    }
    return {
      sizes,
      availableColors,
    };
  }

  addToCart(product: Product) {
    /**
     * Checks:
     * Ensure the product is in_stock
     * Check for authentication status
     */

    if (product.in_stock === 1 || product.in_stock === true) {
      const payload = this.processPayloadForCartAndWishlist(product);
      log.debug('payload: ', payload);
      if (this.credentialsService.isAuthenticated() === true) {
        this.sendCartItemToDB(payload);
      } else {
        this.isLoading = true;
        const colorsAndSizes = this.extractColorsAndSizes(product);
        const cartProduct = { ...product, ...colorsAndSizes };
        cartProduct.size = payload.size;
        cartProduct.selectedPrice = payload.price;

        this.cartService.addToCart(cartProduct, 1, payload.color);
        this.isLoading = false;
        this.notifyService.publishMessages(
          'Product added to cart successfully',
          'success',
          1
        );
      }
    } else {
      this.notifyService.publishMessages(
        'This product is currently out of stock',
        'info',
        1
      );
    }
  }

  addToWishlist(product: Product) {
    // Wishlist/Favorites should only work when user is authenticated
    if (this.credentialsService.isAuthenticated()) {
      const payload = this.processPayloadForCartAndWishlist(product);
      this.sendWishlistItemToDB(payload);
    } else {
      this.notifyService.publishMessages(
        'Log in to add a product to your favourites',
        'info',
        1
      );
    }
  }

  sendCartItemToDB(payload: CartProduct) {
    this.isLoading = true;
    const cartItem$ = this.productsService.addToCart(payload);
    cartItem$.pipe(finalize(() => (this.isLoading = false))).subscribe(
      (res: any) => {
        if (res.error === false) {
          if (res.data && res.data !== true) {
            const cartItem = res.data;
            if (cartItem.product !== null) {
              if (cartItem.product.product_image.includes('|')) {
                cartItem.product.product_image =
                  cartItem.product.product_image.split('|');
              } else {
                cartItem.product.product_image = cartItem.product.product_image;
              }

              const colorsAndSizes = this.extractColorsAndSizes(
                cartItem.product
              );
              cartItem.product.cartId = cartItem.id;
              cartItem.product.selectedPrice = cartItem.price;
              cartItem.product.size = cartItem.size;
              cartItem.product.quantity_ordered = cartItem.quantity;
              cartItem.product.product_name = cartItem.product.product_name;
              cartItem.product.brand_name = cartItem.product.brand.brand_name;
              this.cartService.addToCart(
                { ...cartItem.product, ...colorsAndSizes },
                cartItem.quantity,
                payload.color
              );
              this.notifyService.publishMessages(res.message, 'success', 1);
            }
          }
        } else {
          this.notifyService.publishMessages(res.message, 'danger', 1);
        }
      },
      (error) => {
        log.debug('error: ', error);
      }
    );
  }

  sendWishlistItemToDB(payload: CartProduct) {
    this.isLoading = true;
    const wishlistItem$ = this.productsService.addToWishlist(payload);
    wishlistItem$.pipe(finalize(() => (this.isLoading = false))).subscribe(
      (res: any) => {
        if (res.error === false) {
          if (res.message !== 'Item Already in Wishlist') {
            const wishlistItem = res.data;
            if (wishlistItem && wishlistItem.product !== null) {
              if (wishlistItem.product.product_image.includes('|')) {
                wishlistItem.product.product_image =
                  wishlistItem.product.product_image.split('|');
              } else {
                wishlistItem.product.product_image =
                  wishlistItem.product.product_image;
              }
              wishlistItem.product.wishlistId = wishlistItem.id;
              wishlistItem.product.selectedPrice = wishlistItem.price;
              wishlistItem.product.size = wishlistItem.size;
              wishlistItem.product.quantity_ordered = wishlistItem.quantity;
              wishlistItem.product.product_name =
                wishlistItem.product.product_name;
              wishlistItem.product.brand_name =
                wishlistItem.product.brand.brand_name;
              this.cartService.addToWishList(
                wishlistItem.product,
                wishlistItem.quantity
              );
              this.setUpFaves();
              this.notifyService.publishMessages(res.message, 'success', 1);
            }
          } else {
            this.notifyService.publishMessages(res.message, 'success', 1);
          }
        } else {
          this.notifyService.publishMessages(res.message, 'danger', 1);
        }
      },
      (error: any) => log.debug('error: ', error)
    );
  }

  showPreviousImage(productImages: string[], index: number) {
    if (this.activeSlide <= 0) {
      this.activeSlide = productImages.length - 1;
    } else {
      this.activeSlide--;
    }
  }

  showNextImage(productImages: string[], index: number) {
    if (this.activeSlide >= productImages.length - 1) {
      this.activeSlide = 0;
    } else {
      this.activeSlide++;
    }
  }

  getWishlistProductIndex(productId: any) {
    return this.wishlistIDs.findIndex((id: number) => productId == id);
  }

  getWishlistId(productId: any) {
    const product: CartProduct = this.wishlistProducts.find(
      (product: any) => product.product_id == productId
    );
    return product.wishlistId;
  }

  removeItemFromWishlist(product: CartProduct) {
    // const wishlistId =
    //   this.getWishlistId(product.id) !== undefined
    //     ? this.getWishlistId(product.id)
    //     : null;

    // if (wishlistId !== null) {
    const deleteFromWishlist$ = this.productsService.deleteFromWishlist(
      product.id
    );
    deleteFromWishlist$.subscribe(
      (res: any) => {
        if (res.error === false) {
          log.debug('res: ', res);
          this.cartService.removeFromList(
            this.getWishlistProductIndex(product.id)
          );
          this.setUpFaves();
          if (res.message) {
            this.notifyService.publishMessages(res.message, 'success', 1);
          }
        } else {
          this.notifyService.publishMessages(res.message, 'success', 1);
        }
      },
      (error: any) => log.debug('error: ', error)
    );
    // }
  }
}
