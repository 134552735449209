import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProductDetailsPageComponent } from 'src/app/main/products/product-details/product-details-page/product-details-page.component';
import { Logger } from 'src/services/core/logger/logger.service';

const log =  new Logger("DetailCard");



@Component({
  selector: 'app-product-detail-card',
  templateUrl: './product-detail-card.component.html',
  styleUrls: ['./product-detail-card.component.scss']
})

export class ProductDetailCardComponent implements OnInit {
  @Input() productImages: string[];
  currentImage:string ="";
  showOverlay: boolean = true;
  showOverlayFalse: boolean = false;


  constructor() { }

  ngOnInit(): void {
   
  }

  changeImage(image:any) {
    log.debug(image, 'image')
   this.currentImage = image;
   
   
  }


}


