import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss']
})
export class DrawerComponent implements OnInit {
  @Input() headerTitle: string;
  @Output() closeButtonClicked = new EventEmitter();
  constructor() {}

  ngOnInit() {}

  closeDrawer() {
    this.closeButtonClicked.emit('close-drawer');
  }
}
