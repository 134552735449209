<div class="filter d-flex justify-content-between p-4 mb-5">
  <div class="d-flex flex-md-row flex-column" *ngIf="mobiledrawer == false">
    <!-- <div class="dropdown">
      <p
        data-toggle="dropdown"
        id="dropdownMenuButton1"
        aria-haspopup="true"
        aria-expanded="false"
        class="pointer f-16 font-normal"
      >
        Brand
        <img src="assets/icons/arrow_down_icon.svg" alt="down" class="mr-3" />
      </p>
      <div
        class="dropdown-menu filter__dropdown p-4"
        aria-labelledby="dropdownMenuButton2"
      >
        <p class="f-16 text-dark font-normal mb-4">Select Brand</p>
        <p class="f-14 pointer mb-1 text-dark font-normal">
          clear
          <img src="assets/icons/close-icn.svg" alt="down" class="ml-2" />
        </p>
        <div class="row">
          <div class="col-6">
            <div class="filter__card p-2">
              <img class="mb-1" src="assets/images/puma_logo.svg" alt="logo" />
              <p class="f-14 text-dark text-center font-normal">Puma [900]</p>
            </div>
          </div>
          <div class="col-6">
            <div class="filter__card p-2">
              <img class="mb-1" src="assets/images/puma_logo.svg" alt="logo" />
              <p class="f-14  text-dark text-center font-normal">Puma [900]</p>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="dropdown">
      <p
        data-toggle="dropdown"
        id="dropdownMenuButton1"
        aria-haspopup="true"
        aria-expanded="false"
        class="pointer f-16 mb-0 font-normal"
      >
        Category
        <img src="assets/icons/arrow_down_icon.svg" alt="down" class="mr-3" />
      </p>
      <div
        class="dropdown-menu filter__dropdown p-4"
        aria-labelledby="dropdownMenuButton2"
      >
        <div class="d-flex justify-content-between">
          <p class="f-16 text-dark font-normal mb-4">Select Category</p>
          <img
            (click)="clostFilter()"
            src="assets/icons/close-icn.svg"
            alt="down"
            class="ml-2"
          />
        </div>
        <p
          (click)="resetCategory()"
          class="f-14 mb-2 pointer text-dark font-normal"
        >
          clear
          <img src="assets/icons/close-icn.svg" alt="down" class="ml-2" />
        </p>
        <div
          class="d-flex justify-content-between"
          *ngFor="let category of categories"
        >
          <div class="form-group form-check">
            <input
              (change)="onCheckboxChange($event, category?.id)"
              type="checkbox"
              [checked]="
                selctedCategories.includes(category?.id) ? true : false
              "
              class="form-check-input"
              [id]="category?.id"
            />
            <label
              class="form-check-label text-grey font-normaL f-14"
              [for]="category?.id"
              >{{ category?.cat_name }}</label
            >
          </div>
          <p class="f-14 text-grey font-normal">
            [{{ category?.subcategory?.length }}]
          </p>
        </div>
        <!-- <div class="d-flex mt-2 justify-content-between">
          <div class="form-group form-check">
            <input
              type="checkbox"
              class="form-check-input"
              id="exampleCheck1"
            />
            <label
              class="form-check-label text-grey font-normaL f-14"
              for="exampleCheck1"
              >Clothings</label
            >
          </div>
          <p class="f-14 text-grey font-normal">[1]</p>
        </div> -->
      </div>
    </div>
    <!-- <p class="pointer  mr-3 f-16 font-normal">
      Category
      <img src="assets/icons/arrow_down_icon.svg" alt="down" class="ml-2" />
    </p> -->
    <div class="dropdown" #dropdown>
      <p
        (click)="toggleDropdown()"
        aria-haspopup="true"
        aria-expanded="false"
        class="pointer f-16 mb-0 font-normal"
      >
        Price Range
        <img src="assets/icons/arrow_down_icon.svg" alt="down" class="mr-3" />
      </p>
      <div
        class="dropdown-menu filter__dropdown p-4"
        aria-labelledby="dropdownMenuButton2"
        [class.show]="isOpen"
      >
        <p
          (click)="resetPrice()"
          class="f-14 mb-2 pointer text-dark font-normal"
        >
          reset
          <img src="assets/icons/close-icn.svg" alt="down" class="ml-2" />
        </p>
        <ngx-slider
          [(value)]="minValue"
          [(highValue)]="maxValue"
          [options]="options"
        ></ngx-slider>

        <div class="row mt-3">
          <div class="col-6">
            <div class="form-group">
              <label class="custom-label" for="price">Lowest Price</label>
              <input
                type="number"
                (change)="setLowest($event)"
                [value]="lowPrice"
                class="custom-input w-100"
                placeholder="₦0.00"
                disabled
              />
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="custom-label" for="price">Highest Price</label>
              <input
                type="number"
                (change)="setHighest($event)"
                class="custom-input w-100"
                [value]="highPrice"
                placeholder="₦0.00"
                disabled
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <button (click)="filterAllProducts()" class="btn-custom">
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="dropdown">
      <p
        data-toggle="dropdown"
        id="dropdownMenuButton1"
        aria-haspopup="true"
        aria-expanded="false"
        class="pointer f-16 mb-0 font-normal"
      >
        Color
        <img src="assets/icons/arrow_down_icon.svg" alt="down" class="mr-3" />
      </p>
      <div
        class="dropdown-menu filter__dropdown p-4"
        aria-labelledby="dropdownMenuButton2"
      >
        <p class="f-16 text-dark font-normal mb-4">Select color</p>
        <p
          (click)="resetColor()"
          class="f-14 pointer mb-2 text-dark font-normal"
        >
          clear
          <img src="assets/icons/close-icn.svg" alt="down" class="ml-2" />
        </p>
        <div class="row">
          <div class="col-3" *ngFor="let color of colors">
            <div
              (click)="selectColor(color?.id)"
              [ngClass]="{
                'selected-card': selctedColours.includes(color?.id)
              }"
              class="filter__card--2 mb-2 p-1"
            >
              <div
                [ngStyle]="{
                  'background-color':
                    color?.color !== null
                      ? color?.color.toLowerCase()
                      : 'white',
                  margin: '9px auto'
                }"
                class="rounded-circle color-border color mr-1"
                [ngClass]="{
                  'selected-border':
                    color?.color.toLowerCase() === selectedColor
                }"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="dropdown">
      <p
        data-toggle="dropdown"
        id="dropdownMenuButton1"
        aria-haspopup="true"
        aria-expanded="false"
        class="pointer f-16 mb-0 font-normal"
      >
        Size
        <img src="assets/icons/arrow_down_icon.svg" alt="down" class="mr-3" />
      </p>
      <div
        class="dropdown-menu filter__dropdown p-4"
        aria-labelledby="dropdownMenuButton2"
      >
        <p class="f-16 text-dark font-normal mb-4">Select Size</p>
        <p
          (click)="resetSize()"
          class="f-14 pointer mb-2 text-dark font-normal"
        >
          clear
          <img src="assets/icons/close-icn.svg" alt="down" class="ml-2" />
        </p>
        <div class="row">
          <div class="col-3" *ngFor="let size of sizes">
            <div
              (click)="selectSize(size?.id)"
              [ngClass]="{ 'selected-card': selctedSizes.includes(size?.id) }"
              class="filter__card--2 mb-2 p-1"
            >
              <p class="f-14 text-dark text-center font-normal">
                {{ size?.size }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="dropdown">
      <p
        data-toggle="dropdown"
        id="dropdownMenuButton1"
        aria-haspopup="true"
        aria-expanded="false"
        class="pointer f-16  font-normal"
      >
        Sport
        <img src="assets/icons/arrow_down_icon.svg" alt="down" class="mr-3" />
      </p>
      <div
        class="dropdown-menu filter__dropdown p-4"
        aria-labelledby="dropdownMenuButton2"
      >
        <p class="f-16 text-dark font-normal mb-4">Select Sport</p>
        <p class="f-14 mb-2 pointer text-dark font-normal">
          clear
          <img src="assets/icons/close-icn.svg" alt="down" class="ml-2" />
        </p>
        <div class="d-flex justify-content-between">
          <div class="form-group form-check">
            <input
              type="checkbox"
              class="form-check-input"
              id="exampleCheck1"
            />
            <label
              class="form-check-label text-grey font-normaL f-14"
              for="exampleCheck1"
              >Football</label
            >
          </div>
          <p class="f-14 text-grey font-normal">[1]</p>
        </div>
        <div class="d-flex mt-2 justify-content-between">
          <div class="form-group form-check">
            <input
              type="checkbox"
              class="form-check-input"
              id="exampleCheck1"
            />
            <label
              class="form-check-label text-grey font-normaL f-14"
              for="exampleCheck1"
              >Basketball</label
            >
          </div>
          <p class="f-14 text-grey font-normal">[1]</p>
        </div>
      </div>
    </div> -->
    <!-- <div class="dropdown">
      <p
        data-toggle="dropdown"
        id="dropdownMenuButton1"
        aria-haspopup="true"
        aria-expanded="false"
        class="pointer f-16  font-normal"
      >
        Style
        <img src="assets/icons/arrow_down_icon.svg" alt="down" class="mr-3" />
      </p>
      <div
        class="dropdown-menu filter__dropdown p-4"
        aria-labelledby="dropdownMenuButton2"
      >
        <p class="f-16 text-dark font-normal mb-4">Select Style</p>
        <p class="f-14 mb-2 pointer text-dark font-normal">
          clear
          <img src="assets/icons/close-icn.svg" alt="down" class="ml-2" />
        </p>
        <div class="d-flex justify-content-between">
          <div class="form-group form-check">
            <input
              type="checkbox"
              class="form-check-input"
              id="exampleCheck1"
            />
            <label
              class="form-check-label text-grey font-normaL f-14"
              for="exampleCheck1"
              >Low</label
            >
          </div>
          <p class="f-14 text-grey font-normal">[1]</p>
        </div>
        <div class="d-flex mt-2 justify-content-between">
          <div class="form-group form-check">
            <input
              type="checkbox"
              class="form-check-input"
              id="exampleCheck1"
            />
            <label
              class="form-check-label text-grey font-normaL f-14"
              for="exampleCheck1"
              >Mid</label
            >
          </div>
          <p class="f-14 text-grey font-normal">[1]</p>
        </div>
      </div>
    </div> -->

    <!-- <div class="dropdown">
      <p
        data-toggle="dropdown"
        id="dropdownMenuButton1"
        aria-haspopup="true"
        aria-expanded="false"
        class="pointer f-16  font-normal"
      >
        Team
        <img src="assets/icons/arrow_down_icon.svg" alt="down" class="mr-3" />
      </p>
      <div
        class="dropdown-menu filter__dropdown p-4"
        aria-labelledby="dropdownMenuButton2"
      >
        <p class="f-16 text-dark font-normal mb-4">Select Team</p>
        <p class="f-14 mb-2 pointer text-dark font-normal">
          clear
          <img src="assets/icons/close-icn.svg" alt="down" class="ml-2" />
        </p>
        <div class="d-flex justify-content-between">
          <div class="form-group form-check">
            <input
              type="checkbox"
              class="form-check-input"
              id="exampleCheck1"
            />
            <label
              class="form-check-label text-grey font-normaL f-14"
              for="exampleCheck1"
              >Scuderia Ferrari</label
            >
          </div>
          <p class="f-14 text-grey font-normal">[1]</p>
        </div>
        <div class="d-flex mt-2 justify-content-between">
          <div class="form-group form-check">
            <input
              type="checkbox"
              class="form-check-input"
              id="exampleCheck1"
            />
            <label
              class="form-check-label text-grey font-normaL f-14"
              for="exampleCheck1"
              >Scuderia Ferrari</label
            >
          </div>
          <p class="f-14 text-grey font-normal">[1]</p>
        </div>
      </div>
    </div> -->

    <!-- <div class="dropdown">
      <p
        data-toggle="dropdown"
        id="dropdownMenuButton1"
        aria-haspopup="true"
        aria-expanded="false"
        class="pointer f-16  font-normal"
      >
        Collection
        <img src="assets/icons/arrow_down_icon.svg" alt="down" class="mr-3" />
      </p>
      <div
        class="dropdown-menu filter__dropdown p-4"
        aria-labelledby="dropdownMenuButton2"
      >
        <p class="f-16 text-dark font-normal mb-4">Select Collection</p>
        <p class="f-14 mb-2 pointer text-dark font-normal">
          clear
          <img src="assets/icons/close-icn.svg" alt="down" class="ml-2" />
        </p>
        <div class="d-flex justify-content-between">
          <div class="form-group form-check">
            <input
              type="checkbox"
              class="form-check-input"
              id="exampleCheck1"
            />
            <label
              class="form-check-label text-grey font-normaL f-14"
              for="exampleCheck1"
              >PUMA X Davido</label
            >
          </div>
          <p class="f-14 text-grey font-normal">[1]</p>
        </div>
        <div class="d-flex mt-2 justify-content-between">
          <div class="form-group form-check">
            <input
              type="checkbox"
              class="form-check-input"
              id="exampleCheck1"
            />
            <label
              class="form-check-label text-grey font-normaL f-14"
              for="exampleCheck1"
              >PUMA Rider Pack</label
            >
          </div>
          <p class="f-14 text-grey font-normal">[1]</p>
        </div>
      </div>
    </div> -->
  </div>
  <div *ngIf="mobiledrawer">
    <p (click)="showMobileFilter()" class="pointer f-16 font-normal text-dark">
      Filters
      <img src="assets/icons/setting_icon_black.svg" alt="down" class="ml-2" />
    </p>
  </div>
  <div class="dropdown">
    <p
      data-toggle="dropdown"
      id="dropdownMenuButton"
      aria-haspopup="true"
      aria-expanded="false"
      class="pointer f-16 mb-0 font-normal"
    >
      {{ currentSort | titlecase }}
      <img src="assets/icons/arrow_down_icon.svg" alt="down" class="ml-2" />
    </p>
    <div class="dropdown-menu mobile-drop" aria-labelledby="dropdownMenuButton">
      <a
        class="dropdown-item f-16 pointer font-normal"
        (click)="setSortbyFilter('featured')"
        >Featured</a
      >
      <a
        class="dropdown-item f-16 pointer font-normal"
        (click)="setSortbyFilter('newest')"
        >Newest</a
      >
      <a
        class="dropdown-item f-16 pointer font-normal"
        (click)="setSortbyFilter('Price High - Low')"
        >Price High - Low</a
      >
      <a
        class="dropdown-item f-16 pointer font-normal"
        (click)="setSortbyFilter('Low - Price High')"
      >
        Low - Price High
      </a>
    </div>
  </div>
</div>
<!-- <div class="d-flex mt-4 flex-row">
  <p class="current f-14 font-normal p-2">
    Sport: Football
    <span class="pointer ml-2">x</span>
  </p>
  <p class="f-14 align-self-center ml-3 pointer mb-2 text-dark font-normal">
    clear
    <img src="assets/icons/close-icn.svg" alt="down" class="ml-2" />
  </p>
</div> -->
<app-drawer
  *ngIf="isDrawerOpen"
  [headerTitle]="''"
  (closeButtonClicked)="closeDrawer($event)"
>
  <div drawerBody>
    <div class="mobile mt-4">
      <a
        data-toggle="collapse"
        href="#collapseExample"
        role="button"
        aria-expanded="false"
        aria-controls="collapseExample"
        class="d-flex mobile__dropdown p-4 mb-2 w-100 pointer justify-content-between"
      >
        <span class="font-normal f-16">Category</span>
        <img src="assets/icons/arrow-right.svg" alt="cart" />
      </a>
      <div class="collapse px-4" id="collapseExample">
        <p class="f-16 text-dark font-normal mb-4">Select Category</p>
        <p
          (click)="resetCategory()"
          class="f-14 mb-2 pointer text-dark font-normal"
        >
          clear
          <img src="assets/icons/close-icn.svg" alt="down" class="ml-2" />
        </p>

        <div
          class="d-flex justify-content-between"
          *ngFor="let category of categories"
        >
          <div class="form-group form-check">
            <input
              (change)="onCheckboxChange($event, category?.id)"
              type="checkbox"
              [checked]="
                selctedCategories.includes(category?.id) ? true : false
              "
              class="form-check-input"
              [id]="category?.id"
            />
            <label
              class="form-check-label text-grey font-normaL f-14"
              [for]="category?.id"
              >{{ category?.cat_name }}</label
            >
          </div>
          <p class="f-14 text-grey font-normal">
            [{{ category?.subcategory?.length }}]
          </p>
        </div>
      </div>
      <a
        data-toggle="collapse"
        href="#collapseExample1"
        role="button"
        aria-expanded="false"
        aria-controls="collapseExample1"
        class="d-flex mobile__dropdown p-4 mb-2 w-100 pointer justify-content-between"
      >
        <span class="font-normal f-16">Price Range</span>
        <img src="assets/icons/arrow-right.svg" alt="cart" />
      </a>
      <div class="collapse px-4" id="collapseExample1">
        <p
          (click)="resetPrice()"
          class="f-14 mb-2 pointer text-dark font-normal"
        >
          clear
          <img src="assets/icons/close-icn.svg" alt="down" class="ml-2" />
        </p>
        <ngx-slider
          [(value)]="minValue"
          [(highValue)]="maxValue"
          [options]="options"
        ></ngx-slider>

        <div class="row mt-3">
          <div class="col-6">
            <div class="form-group">
              <label class="custom-label" for="price">Lowest Price</label>
              <input
                type="number"
                (change)="setLowest($event)"
                [value]="lowPrice"
                class="custom-input w-100"
                placeholder="₦0.00"
                disabled
              />
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="custom-label" for="price">Highest Price</label>
              <input
                type="number"
                (change)="setHighest($event)"
                class="custom-input w-100"
                [value]="highPrice"
                placeholder="₦0.00"
                disabled
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <button (click)="filterAllProducts()" class="btn-custom">
              Apply
            </button>
          </div>
        </div>
      </div>
      <!-- <a
        data-toggle="collapse"
        href="#collapseExample2"
        role="button"
        aria-expanded="false"
        aria-controls="collapseExample2"
        class="d-flex mobile__dropdown p-4 mb-2 w-100 pointer justify-content-between"
      >
        <span class="font-normal f-16">Color</span>
        <img src="assets/icons/arrow-right.svg" alt="cart" />
      </a>
      <div class="collapse px-4" id="collapseExample2">
        <p class="f-16 text-dark font-normal mb-4">Select color</p>
        <p
          (click)="resetColor()"
          class="f-14 pointer mb-2 text-dark font-normal"
        >
          clear
          <img src="assets/icons/close-icn.svg" alt="down" class="ml-2" />
        </p>
        <div class="row">
          <div class="col-3" *ngFor="let color of colors">
            <div
              (click)="selectColor(color?.id)"
              [ngClass]="{
                'selected-card': selctedColours.includes(color?.id)
              }"
              class="filter__card--2 mb-2 p-1"
            >
              <div
                [ngStyle]="{
                  'background-color':
                    color?.color !== null
                      ? color?.color.toLowerCase()
                      : 'white',
                  margin: '9px auto'
                }"
                class="rounded-circle color-border color mr-1"
                [ngClass]="{
                  'selected-border':
                    color?.color.toLowerCase() === selectedColor
                }"
              ></div>
            </div>
          </div>
        </div>
      </div> -->
      <a
        data-toggle="collapse"
        href="#collapseExample3"
        role="button"
        aria-expanded="false"
        aria-controls="collapseExample3"
        class="d-flex mobile__dropdown p-4 mb-2 w-100 pointer justify-content-between"
      >
        <span class="font-normal f-16">Size</span>
        <img src="assets/icons/arrow-right.svg" alt="cart" />
      </a>
      <div class="collapse px-4" id="collapseExample3">
        <p class="f-16 text-dark font-normal mb-4">Select Size</p>
        <p
          (click)="resetSize()"
          class="f-14 pointer mb-2 text-dark font-normal"
        >
          clear
          <img src="assets/icons/close-icn.svg" alt="down" class="ml-2" />
        </p>
        <div class="row">
          <div class="col-3" *ngFor="let size of sizes">
            <div
              (click)="selectSize(size?.id)"
              [ngClass]="{ 'selected-card': selctedSizes.includes(size?.id) }"
              class="filter__card--2 mb-2 p-1"
            >
              <p class="f-14 text-dark text-center font-normal">
                {{ size?.size }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- <a
        data-toggle="collapse"
        href="#collapseExample4"
        role="button"
        aria-expanded="false"
        aria-controls="collapseExample4"
        class="d-flex mobile__dropdown p-4 mb-2 w-100 pointer justify-content-between"
      >
        <span class="font-normal f-16">Style</span>
        <img src="assets/icons/arrow-right.svg" alt="cart" />
      </a>
      <div class="collapse px-4" id="collapseExample4">
        <p class="f-16 text-dark font-normal mb-4">Select Style</p>
        <p class="f-14 mb-2 pointer text-dark font-normal">
          clear
          <img src="assets/icons/close-icn.svg" alt="down" class="ml-2" />
        </p>
        <div class="d-flex justify-content-between">
          <div class="form-group form-check">
            <input
              type="checkbox"
              class="form-check-input"
              id="exampleCheck1"
            />
            <label
              class="form-check-label text-grey font-normaL f-14"
              for="exampleCheck1"
              >Low</label
            >
          </div>
          <p class="f-14 text-grey font-normal">[1]</p>
        </div>
        <div class="d-flex mt-2 justify-content-between">
          <div class="form-group form-check">
            <input
              type="checkbox"
              class="form-check-input"
              id="exampleCheck1"
            />
            <label
              class="form-check-label text-grey font-normaL f-14"
              for="exampleCheck1"
              >Mid</label
            >
          </div>
          <p class="f-14 text-grey font-normal">[1]</p>
        </div>
      </div> -->
    </div>
  </div>
</app-drawer>
