<div class="header sticky-top">
  <nav
    class="navbar navbar-expand-lg navbar-dark container-fluid-md container-lg"
  >
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      aria-controls="navbarTogglerDemo03"
      aria-expanded="false"
      aria-label="Toggle navigation"
      (click)="openDrawer()"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <a class="navbar-brand" routerLink="/">
      <img src="assets/images/logo_white_1.svg" alt="logo" />
    </a>

    <div class="collapse navbar-collapse" id="navbarTogglerDemo03">
      <div *ngIf="showSearchBar" class="mx-auto">
        <form [formGroup]="searchForm">
          <div class="form-group my-1">
            <input
              type="text"
              formControlName="search"
              (keyup.enter)="search($event)"
              class="search-input pr-5"
              placeholder="Search name product, or etc"
            />
            <img
              class="search_icon d-lg-block d-none"
              src="assets/icons/search_faded_icon.svg"
              alt="search"
            />
          </div>
        </form>
      </div>
      <ul
        *ngIf="showSearchBar == false"
        class="navbar-nav mx-auto mt-2 mt-lg-0"
      >
        <!-- <li class="nav-item active" *ngFor="let category of categories">
          <a
            class="nav-link font-normal pointer text-white px-lg-2 px-md-0 f-14"
            (click)="setShowDropped(category?.id)"
            >{{ category?.cat_name }} <span class="sr-only">(current)</span></a
          >
        </li> -->
        <li class="nav-item">
          <a
            (click)="fetchDropDownData('women')"
            class="nav-link show-button font-normal pointer px-lg-2 px-md-0 text-white f-14"
            >Women</a
          >
        </li>
        <li class="nav-item">
          <a
            (click)="fetchDropDownData('men')"
            class="nav-link show-button font-normal pointer px-lg-2 px-md-0 text-white f-14"
            >Men</a
          >
        </li>
        <li class="nav-item">
          <a
            (click)="fetchDropDownData('kids')"
            class="nav-link show-button font-normal pointer px-lg-2 px-md-0 text-white f-14"
            >Kids</a
          >
        </li>
        <li class="nav-item">
          <a
            (click)="goToArrivals()"
            class="nav-link font-normal pointer px-lg-2 px-md-0 text-white f-14"
            >New Arrivals</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link show-button font-normal pointer px-lg-2 px-md-0 text-white f-14"
            >Accessories</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link show-button font-normal pointer px-lg-2 px-md-0 text-white f-14"
            (click)="fetchDropDownDataCollection()"
            >Collections & Collaborations</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link show-button font-normal pointer px-lg-2 px-md-0 text-white f-14"
            (click)="fetchDropDownDataBrand()"
            >Brands</a
          >
        </li>
      </ul>
      <div class="my-2 my-lg-0">
        <img
          *ngIf="showSearchBar"
          (click)="setShowSearchBar()"
          src="assets/icons/close_circle_icon.svg"
          alt="close"
          class="pointer"
        />
        <ul
          *ngIf="showSearchBar == false"
          class="navbar-nav mx-auto mt-2 mt-lg-0"
        >
          <li class="nav-item active">
            <a
              class="nav-link px-lg-3 px-md-0 font-normal text-white f-14"
              (click)="setShowSearchBar()"
            >
              <img src="assets/icons/search_icon.svg" alt="search" />
            </a>
          </li>
          <li class="nav-item pointer">
            <a
              class="nav-link px-lg-3 px-md-0 font-normal f-14 text-white badge-container"
              (click)="openCart()"
            >
              <img src="assets/icons/cart_icon.svg" alt="cart" />
              <span *ngIf="cartTotal != 0" class="item-count">{{
                cartTotal
              }}</span>
            </a>
          </li>
          <li class="nav-item pointer" *ngIf="isLoggedIn()">
            <a
              class="nav-link px-lg-3 px-md-0 font-normal text-white f-14 badge-container"
              (click)="openFavorites()"
            >
              <img src="assets/icons/wishlist_icon.svg" alt="wishlist" />
              <span *ngIf="wishlistTotal != '0'" class="wish-count">{{
                wishlistTotal
              }}</span>
            </a>
          </li>
          <li *ngIf="isLoggedIn() === false" class="nav-item">
            <a
              class="nav-link px-lg-4 px-md-0 font-normal text-white f-14"
              routerLink="/auth"
              >Sign In</a
            >
          </li>
          <div *ngIf="isLoggedIn()" class="dropdown profile">
            <a
              class="btn btn-secondary"
              href="#"
              role="button"
              id="dropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img
                [src]="credentials()?.image || 'assets/images/user-icon.png'"
                class="profile__pic"
                alt="profile pic"
              />
            </a>

            <div
              class="dropdown-menu profile__dropdown p-4"
              aria-labelledby="dropdownMenuLink"
            >
              <a class="dropdown-item d-flex mb-2">
                <img
                  [src]="credentials()?.image || 'assets/images/user-icon.svg'"
                  class="profile__pic mr-2"
                  alt="profile pic"
                />
                <div class="d-flex flex-column">
                  <h4 class="f-16 profile__black-text font-normal">
                    Hi, {{ credentials()?.firstname }}
                  </h4>
                  <span class="f-12 profile__grey-text font-normal"
                    >Member since
                    {{ credentials()?.created_at | date : 'y' }}
                  </span>
                </div>
              </a>
              <a
                class="dropdown-item profile__black-text mb-2 f-16 font-normal"
                (click)="goToRoute('/profile/edit-account')"
                >My Account</a
              >
              <a
                class="dropdown-item profile__black-text mb-2 f-16 font-normal"
                (click)="goToRoute('/profile/orders')"
                >Track Orders</a
              >
              <a
                class="dropdown-item profile__black-text mb-2 f-16 font-normal"
                (click)="goToRoute('/profile/favorites')"
                >Favorites</a
              >
              <a
                class="dropdown-item profile__black-text mb-2 f-16 font-normal"
                (click)="goToRoute('/profile/settings')"
                >Settings</a
              >
              <a
                class="dropdown-item profile__black-text f-16 font-normal"
                data-toggle="modal"
                data-target="#logoutModal"
                >Logout</a
              >
            </div>
          </div>
        </ul>
      </div>
    </div>
  </nav>
  <section *ngIf="dropped" class="categories">
    <div class="container py-5">
      <div *ngIf="isOthers == true" class="row">
        <!-- offset-3 -->
        <div
          *ngFor="let division of categorySelected?.product_division"
          class="col-3"
        >
          <ul class="list-unstyled">
            <li
              (click)="saveDetails(division)"
              routerLink="/sub-category/{{ division?.id }}"
              class="text-white font-normal pointer f-20 mb-1"
            >
              {{ division?.name | titlecase }}
            </li>
            <ul
              *ngFor="let business of division?.business_unit"
              class="list-unstyled"
            >
              <li
                class="ml-2 text-dark_grey font-normal pointer f-16 mb-1"
                routerLink="/child-category/{{ business?.id }}"
                (click)="saveDetailsProductDivisions(business, division)"
              >
                {{ business?.name | titlecase }}
              </li>
            </ul>
          </ul>
        </div>
      </div>

      <!-- Brands -->
      <div *ngIf="isBrand == true" class="row">
        <div *ngFor="let data of brands" class="col-3">
          <ul class="list-unstyled">
            <li
              (click)="saveDetailsBrand(data)"
              routerLink="/brand-products/{{ data?.id }}"
              class="text-white font-normal pointer f-20 mb-1"
            >
              {{ data?.brand_name | titlecase }}
            </li>
          </ul>
        </div>
      </div>

      <!-- Collections -->
      <div *ngIf="isCollection == true" class="row">
        <div *ngFor="let data of collections" class="col-3">
          <ul class="list-unstyled">
            <li
              (click)="saveDetailsCollection(data)"
              routerLink="/collab/{{ data?.id }}"
              class="text-white font-normal pointer f-20 mb-1"
            >
              {{ data?.name | titlecase }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</div>

<!-- mobile drawer -->
<app-drawer
  *ngIf="isDrawerOpen"
  [headerTitle]="''"
  (closeButtonClicked)="closeDrawer($event)"
>
  <div drawerBody>
    <div class="d-flex justify-content-start flex-row">
      <a
        class="nav-link px-lg-3 px-md-3 pointer font-normal text-white f-14"
        (click)="setShowSearchBarMobile()"
      >
        <img src="assets/icons/search_black_icon.svg" alt="search" />
      </a>
      <a
        class="nav-link px-lg-3 px-md-3 font-normal pointer f-14 text-white badge-container"
        (click)="openCart()"
      >
        <img src="assets/icons/cart_black_icon.svg" alt="cart" />
        <span
          *ngIf="cartTotal !== 0"
          class="badge badge-pill text-light badge-danger"
        >
          {{ cartTotal }}
        </span>
      </a>
      <a
        *ngIf="isLoggedIn()"
        class="nav-link px-lg-3 px-md-3 font-normal pointer text-white f-14 badge-container"
        (click)="openFavorites()"
      >
        <img src="assets/icons/wishlist_black_icon.svg" alt="wishlist" />
        <span
          *ngIf="wishlistTotal !== 0"
          class="badge badge-pill text-light badge-danger"
        >
          {{ wishlistTotal }}
        </span>
      </a>

      <a
        *ngIf="isLoggedIn() === false"
        (click)="isDrawerOpen = false"
        class="nav-link px-lg-4 px-md-0 text-dark mt-2 font-weight-medium f-14"
        routerLink="/auth"
        >Sign In</a
      >

      <div *ngIf="isLoggedIn()" class="dropdown profile">
        <a
          class="btn btn-secondary"
          href="#"
          role="button"
          id="dropdownMenuLink"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <img
            src="assets/images/user-icon.png"
            class="profile__pic"
            alt="profile pic"
          />
        </a>

        <div
          class="dropdown-menu profile__dropdown2 p-4"
          aria-labelledby="dropdownMenuLink"
        >
          <a class="dropdown-item d-flex mb-2">
            <img
              src="assets/images/user-icon.png"
              class="profile__pic mr-2"
              alt="profile pic"
            />
            <div class="d-flex flex-column">
              <h4 class="f-16 profile__black-text font-normal">
                Hi, {{ credentials()?.firstname }}
              </h4>
              <span class="f-12 profile__grey-text font-normal"
                >Member since
                {{ credentials()?.created_at | date : 'y' }}
              </span>
            </div>
          </a>
          <a
            class="dropdown-item profile__black-text mb-2 f-16 font-normal"
            (click)="goToRoute('/profile/edit-account')"
            >My Account</a
          >
          <a
            class="dropdown-item profile__black-text mb-2 f-16 font-normal"
            (click)="goToRoute('/profile/orders')"
            >Track Orders</a
          >
          <a
            class="dropdown-item profile__black-text mb-2 f-16 font-normal"
            (click)="goToRoute('/profile/favorites')"
            >Favorites</a
          >
          <a
            class="dropdown-item profile__black-text mb-2 f-16 font-normal"
            (click)="goToRoute('/profile/settings')"
            >Settings</a
          >
          <a
            class="dropdown-item profile__black-text f-16 font-normal"
            data-toggle="modal"
            data-target="#logoutModal"
            >Logout</a
          >
        </div>
      </div>
    </div>
    <div *ngIf="showSearchBarMobile" class="mx-auto">
      <form [formGroup]="searchForm">
        <div class="form-group my-1">
          <input
            type="text"
            (keyup.enter)="search($event)"
            formControlName="search"
            class="custom-input w-100 pr-5"
            placeholder="Search name product, or etc"
          />
        </div>
      </form>
    </div>
    <div class="mobile mt-4">
      <a
        data-toggle="collapse"
        href="#collapseExample1"
        role="button"
        aria-expanded="false"
        aria-controls="collapseExample1"
        class="d-flex mobile__dropdown p-4 mb-2 w-100 pointer justify-content-between"
        (click)="getMobileDropdown('men', 1)"
      >
        <span class="font-normal f-16">Men</span>
        <img src="assets/icons/arrow-right.svg" alt="cart" />
      </a>
      <div
        *ngFor="let division of categorySelected?.product_division"
        class="collapse px-4"
        id="collapseExample1"
      >
        <ul class="list-unstyled mt-4">
          <li
            class="text-dark_grey mb-2 font-normal pointer_2 f-20"
            routerLink="/sub-category/{{ division?.id }}"
            (click)="saveDetails(division)"
          >
            {{ division?.name | titlecase }}
          </li>
          <ul
            *ngFor="let business of division?.business_unit"
            class="list-unstyled"
          >
            <li
              routerLink="/child-category/{{ business?.id }}"
              (click)="saveDetailsProductDivisions(business, division)"
              class="ml-2 text-dark_grey font-normal pointer f-16 mb-1"
            >
              {{ business?.name | titlecase }}
            </li>
          </ul>
        </ul>
      </div>
      <a
        data-toggle="collapse"
        href="#collapseExample2"
        role="button"
        aria-expanded="false"
        aria-controls="collapseExample2"
        class="d-flex mobile__dropdown p-4 mb-2 w-100 pointer justify-content-between"
        (click)="getMobileDropdown('women')"
      >
        <span class="font-normal f-16">Women</span>
        <img src="assets/icons/arrow-right.svg" alt="cart" />
      </a>
      <div
        *ngFor="let division of categorySelected?.product_division"
        class="collapse px-4"
        id="collapseExample2"
      >
        <ul class="list-unstyled mt-4">
          <li
            class="text-dark_grey mb-2 font-normal pointer_2 f-20"
            routerLink="/sub-category/{{ division?.id }}"
            (click)="saveDetails(division)"
          >
            {{ division?.name | titlecase }}
          </li>
          <ul
            *ngFor="let business of division?.business_unit"
            class="list-unstyled"
          >
            <li
              routerLink="/child-category/{{ business?.id }}"
              (click)="saveDetailsProductDivisions(business, division)"
              class="ml-2 text-dark_grey font-normal pointer f-16 mb-1"
            >
              {{ business?.name | titlecase }}
            </li>
          </ul>
        </ul>
      </div>
      <a
        data-toggle="collapse"
        href="#collapseExample3"
        role="button"
        aria-expanded="false"
        aria-controls="collapseExample3"
        class="d-flex mobile__dropdown p-4 mb-2 w-100 pointer justify-content-between"
        (click)="getMobileDropdown('kids', 3)"
      >
        <span class="font-normal f-16">Kids</span>
        <img src="assets/icons/arrow-right.svg" alt="cart" />
      </a>

      <div
        *ngFor="let division of categorySelected?.product_division"
        class="collapse px-4"
        id="collapseExample3"
      >
        <ul class="list-unstyled mt-4">
          <li
            class="text-dark_grey mb-2 font-normal pointer_2 f-20"
            routerLink="/sub-category/{{ division?.id }}"
            (click)="saveDetails(division)"
          >
            {{ division?.name | titlecase }}
          </li>
          <ul
            *ngFor="let business of division?.business_unit"
            class="list-unstyled"
          >
            <li
              routerLink="/child-category/{{ business?.id }}"
              (click)="saveDetailsProductDivisions(business, division)"
              class="ml-2 text-dark_grey font-normal pointer f-16 mb-1"
            >
              {{ business?.name | titlecase }}
            </li>
          </ul>
        </ul>
      </div>
      <a
        (click)="goToRoute('/new-arrivals')"
        class="d-flex mobile__dropdown p-4 mb-2 w-100 pointer justify-content-between"
      >
        <span class="font-normal f-16">New Arrivals</span>
        <img src="assets/icons/arrow-right.svg" alt="cart" />
      </a>
      <a
        class="d-flex mobile__dropdown p-4 mb-2 w-100 pointer justify-content-between"
      >
        <span class="font-normal f-16">Accessories</span>
        <img src="assets/icons/arrow-right.svg" alt="cart" />
      </a>
      <a
        data-toggle="collapse"
        href="#collapseExample4"
        role="button"
        aria-expanded="false"
        aria-controls="collapseExample4"
        class="d-flex mobile__dropdown p-4 mb-2 w-100 pointer justify-content-between"
        (click)="getMobileDropdownForCollection()"
      >
        <span class="font-normal f-16">Collections & Collaborations</span>
        <img src="assets/icons/arrow-right.svg" alt="cart" />
      </a>

      <div
        *ngFor="let division of collections"
        class="collapse px-4"
        id="collapseExample4"
      >
        <ul class="list-unstyled mt-4">
          <li
            class="text-dark_grey mb-2 font-normal pointer_2 f-20"
            routerLink="/collab/{{ division?.id }}"
            (click)="saveDetailsCollection(division)"
          >
            {{ division?.name | titlecase }}
          </li>
        </ul>
      </div>
      <a
        data-toggle="collapse"
        href="#collapseExample5"
        role="button"
        aria-expanded="false"
        aria-controls="collapseExample5"
        class="d-flex mobile__dropdown p-4 mb-2 w-100 pointer justify-content-between"
        (click)="getMobileDropdownForBrands()"
      >
        <span class="font-normal f-16">Brands</span>
        <img src="assets/icons/arrow-right.svg" alt="cart" />
      </a>

      <div
        *ngFor="let brand of brands"
        class="collapse px-4"
        id="collapseExample5"
      >
        <ul class="list-unstyled mt-4">
          <li
            class="text-dark_grey mb-2 font-normal pointer_2 f-20"
            routerLink="/brand-products/{{ brand?.id }}"
            (click)="saveDetailsBrand(brand)"
          >
            {{ brand?.brand_name | titlecase }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</app-drawer>

<button
  data-toggle="collapse"
  data-target="#collapseExample1"
  role="button"
  aria-expanded="false"
  aria-controls="collapseExample1"
  class="d-none"
  id="getmobileCOllapse1"
></button>
<button
  data-toggle="collapse"
  data-target="#collapseExample2"
  role="button"
  aria-expanded="false"
  aria-controls="collapseExample2"
  class="d-none"
  id="getmobileCOllapse2"
></button>
<button
  data-toggle="collapse"
  data-target="#collapseExample3"
  role="button"
  aria-expanded="false"
  aria-controls="collapseExample3"
  class="d-none"
  id="getmobileCOllapse3"
></button>
<button
  data-toggle="collapse"
  data-target="#collapseExample4"
  role="button"
  aria-expanded="false"
  aria-controls="collapseExample4"
  class="d-none"
  id="getmobileCOllaps4"
></button>

<button
  data-toggle="collapse"
  data-target="#collapseExample5"
  role="button"
  aria-expanded="false"
  aria-controls="collapseExample5"
  class="d-none"
  id="getmobileCOllapse5"
></button>

<div
  class="modal fade"
  id="logoutModal"
  tabindex="-1"
  aria-labelledby="logoutModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-note">
    <div class="modal-content">
      <div class="modal-body p-70">
        <div class="container logout">
          <div class="d-flex flex-column mt-5 justify-content-center">
            <img
              src="assets/images/logout_pic.svg"
              alt="logout"
              class="logout__img"
            />
            <p class="font-normal f-14 mb-4 text-center p-0 mt-2">
              Are you sure you want to log out?
            </p>
          </div>

          <div class="row mt-4 mb-5 px-0">
            <div class="col-md-6 mb-3 col-12">
              <button
                id="dismissLogoutModal"
                class="btn-outline"
                data-dismiss="modal"
                aria-label="Close"
              >
                Cancel
              </button>
            </div>
            <div class="col-md-6 mb-3col-12">
              <button class="btn-custom" (click)="logout()">Logout</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
