<div class="d-block product-card pointer">
  <div class="img-container carousel">
    <!-- <img
      (click)="goToProductDetails(product)"
      *ngIf="product?.product_image && isArray(product?.product_image)"
      [src]="(product?.product_image)[0]"
      [alt]="product?.product_name"
      class="img-fluid prod-img"
    />
    <img
      (click)="goToProductDetails(product)"
      *ngIf="product?.product_image && !isArray(product?.product_image)"
      [src]="product?.product_image"
      [alt]="product?.product_name"
      class="img-fluid prod-img"
    /> -->
    <!-- <img
      (click)="goToProductDetails(product)"
      *ngIf="!product?.featured_image && !product?.product_image"
      src="assets/images/no-image.jpeg"
      [alt]="product?.product_name"
      class="img-fluid prod-img"
    /> -->
    <div *ngIf="product?.product_image && isArray(product?.product_image)">
      <div *ngFor="let img of product?.product_image; let idx = index">
        <div
          *ngIf="activeSlide === idx"
          class="carousel__controls d-flex flex-row justify-content-between"
        >
          <button
            class="previous btn p-0"
            (click)="showPreviousImage(product?.product_image, idx)"
          >
            <img
              src="assets/icons/left_icon.svg"
              class="mr-2 pointer"
              alt="left icon"
            />
          </button>
          <button
            class="next btn p-0"
            (click)="showNextImage(product?.product_image, idx)"
          >
            <img
              src="assets/icons/right_icon.svg"
              class="pointer"
              alt="right icon"
            />
          </button>
        </div>

        <ng-container *ngIf="activeSlide === idx">
          <img
            (click)="goToProductDetails(product)"
            [src]="img"
            [alt]="product?.product_name"
            class="img-fluid prod-img"
          />
        </ng-container>
      </div>
    </div>

    <img
      (click)="goToProductDetails(product)"
      *ngIf="
        product?.featured_image &&
        (!product?.product_image || !isArray(product?.product_image))
      "
      [src]="product?.featured_image"
      [alt]="product?.product_name"
      class="img-fluid prod-img"
    />
  </div>

  <div class="cart-btn__container justify-content-center">
    <button
      class="btn-custom cart-btn btn"
      id="cart-btn"
      (click)="handleProductToCartAddition(product)"
    >
      <span *ngIf="isLoading === false" class="mr-3">&#43;</span>
      ADD TO BAG
      <span *ngIf="isLoading === true" class="ml-3">
        <i class="fa fa-spinner fa-spin"></i>
      </span>
    </button>
  </div>
  <div class="">
    <p class="my-2 txt-blk" (click)="goToProductDetails(product)">
      {{ product?.product_name | slice : 0 : 24 }}
      <span *ngIf="product?.product_name?.length > 24">...</span>
    </p>
    <div class="mb-2 d-flex justify-content-between">
      <div
        class="d-flex align-items-center"
        (click)="goToProductDetails(product)"
      >
        <p class="mb-0 txt-gy">{{ product?.brand?.brand_name || 'N/A' }}</p>
        &nbsp;
        <svg
          width="6"
          height="6"
          viewBox="0 0 6 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="3.16699" cy="3" r="2.5" fill="#C6C8D3" />
        </svg>
        &nbsp;
        <div class="d-flex align-items-center">
          <img src="assets/icons/rating-star.svg" alt="rating-star" />
          <span>{{ product?.ratings }}</span>
        </div>
      </div>
      <!-- <div class="row mr-1" *ngIf="product?.product_variation">
        <div *ngFor="let item of product?.product_variation">
       
          <div
            *ngIf="item?.product_color !== null && item?.product_color?.color"
            (click)="handleColorSelection(item)"
            [ngStyle]="{
              'background-color':
                item?.product_color?.color !== null
                  ? item?.product_color?.color?.toLowerCase()
                  : 'white'
            }"
            class="rounded-circle color-border color mr-1"
            [ngClass]="{
              'selected-border':
                item?.product_color?.color?.toLowerCase() ===
                selectedVariant?.color
            }"
          ></div>
          <div
            *ngIf="item?.product_color === null"
            (click)="handleColorSelection(item)"
            [ngStyle]="{
              'background-color':
                item?.color !== null ? item?.color?.toLowerCase() : 'white'
            }"
            class="rounded-circle color-border color mr-1"
            [ngClass]="{
              'selected-border':
                item?.color?.toLowerCase() === selectedVariant?.color
            }"
          ></div>
          <span
            class="px-1 txt-gy"
            *ngIf="!item?.product_color === null && item?.color === null"
            >N/A</span
          >
        </div>
      </div> -->
    </div>
    <div class="d-flex justify-content-between align-items-center">
      <p class="mb-0">
        <span class="txt-blk">
          {{
            selectedVariant?.price || (product?.product_variation)[0]?.price
              | currency : '₦'
          }}
        </span>
        &nbsp;
        <span
          *ngIf="
            product?.discount_type &&
            product?.discount_type?.toLowerCase() === 'percentage'
          "
        >
          <span class="old-price">{{ product?.discount }}% Off</span>
        </span>
        <span
          *ngIf="
            product?.discount_type &&
            product?.discount_type?.toLowerCase() === 'naira'
          "
        >
          <span class="old-price"
            >{{ product?.discount | currency : '₦' }} Off</span
          >
        </span>
      </p>
    </div>
  </div>
  <div class="brand-section p-3">
    <!-- <img src="{{ product?.brandImage }}" alt="brand" width="35" height="35" /> -->
    <div></div>
    <span
      (click)="handleProductToWishlistAddition(product)"
      class="wishlist"
      *ngIf="!wishlistIDs.includes(product.id)"
      ><img src="assets/icons/favorite.svg" alt="favorite" class="pointer"
    /></span>
    <span
      (click)="removeItemFromWishlist(product)"
      *ngIf="wishlistIDs.includes(product.id)"
      ><img
        src="assets/icons/added-to-favorites.svg"
        alt="favorite"
        class="pointer"
    /></span>
  </div>
</div>
