<app-alert></app-alert>
<app-header
  *ngIf="showHeaderAndFooter"
  (openDrawerFromHeader)="openCart($event)"
  (openFavoritesFromHeader)="openFavorites($event)"
  [cartCount]="cartItems.length"
  [wishlistCount]="wishlist.length"
></app-header>
<app-loader></app-loader>
<router-outlet (activate)="onActivate($event)"></router-outlet>
<app-footer *ngIf="showHeaderAndFooter"></app-footer>

<app-drawer
  *ngIf="isDrawerOpen"
  [headerTitle]="drawerHeader"
  (closeButtonClicked)="closeDrawer($event)"
>
  <div drawerBody>
    <div *ngIf="cartItems.length > 0">
      <div class="cart-items px-3">
        <div *ngFor="let cartItem of cartItems; let i = index">
          <div class="my-3">
            <app-cart-product
              [product]="cartItem"
              [forCart]="true"
              [selectedProductIndex]="i"
            ></app-cart-product>
          </div>
          <hr />
        </div>
      </div>

      <div class="row mt-3 px-3">
        <div class="col-md-12">
          <!-- <form class="form row mb-3">
            <div class="col-md-8 form-group">
              <label for="promocode" class="sr-only">Promo code</label>
              <input
                id="promocode"
                placeholder="Enter a promo code"
                type="text"
                class="custom-input w-100"
              />
            </div>
            <div class="col-md-4 p-0">
              <button type="submit" class="btn btn-custom w-100 btn-custom-2">
                Apply
              </button>
            </div>
          </form> -->

          <div class="d-flex flex-column mb-4">
            <div class="d-flex flex-row justify-content-between">
              <p class="font-normal">Subtotal</p>
              <p class="amount font-weight-medium text-right">
                {{ cartTotal | currency : '₦' }}
              </p>
            </div>
            <div class="d-flex flex-row justify-content-between">
              <p class="font-normal">Estimated Delivery &amp; Handling</p>
              <p class="amount font-weight-medium text-right">
                {{ totalShippingFees | currency : '₦' }}
              </p>
            </div>
            <div class="d-flex flex-row justify-content-between">
              <p class="font-weight-medium mb-0 p-0">Total</p>
              <p class="amount font-weight-medium text-right">
                {{ cartTotal + totalShippingFees | currency : '₦' }}
              </p>
            </div>
          </div>

          <button class="btn btn-custom btn-block" (click)="goToCheckout()">
            Checkout
          </button>
        </div>
      </div>
    </div>

    <div *ngIf="cartItems.length === 0">
      <div
        class="h-100 d-flex flex-column justify-content-center align-items-center text-center"
      >
        <div class="h-100 empty-state">
          <img src="assets/icons/empty-cart.svg" alt="" />
          <div>
            <h5 class="font-weight-medium mb-2">Your Shopping Cart is Empty</h5>
            <p class="mb-3 p-0 font-normal">Visit our product page</p>

            <button class="btn btn-custom" (click)="goToProducts()">
              Our Products
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-drawer>

<app-drawer
  *ngIf="isFavoritesDrawerOpen"
  [headerTitle]="favoritesDrawerHeader"
  (closeButtonClicked)="closeFavoritesDrawer($event)"
>
  <div drawerBody>
    <div class="cart-items px-3">
      <div *ngFor="let faveItem of wishlist; let i = index">
        <div class="my-3">
          <app-favorite-product
            [product]="faveItem"
            [selectedProductIndex]="i"
          ></app-favorite-product>
        </div>
        <hr />
      </div>
    </div>

    <div *ngIf="wishlist.length === 0">
      <div
        class="h-100 d-flex flex-column justify-content-center align-items-center text-center"
      >
        <div class="h-100 empty-state">
          <img src="assets/icons/empty-cart.svg" alt="" />
          <div>
            <h5 class="font-weight-medium mb-2">
              Your Favorites List is Empty
            </h5>
            <p class="mb-3 p-0 font-normal">Visit our product page</p>

            <button class="btn btn-custom" (click)="goToProducts()">
              Our Products
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-drawer>
