import { Component, OnInit, Input } from '@angular/core';
import { CartService } from 'src/services/cart/cart.service';
import { CartProduct } from 'src/services/classes/models/product';
import { NotificationsService } from 'src/services/classes/notifications/notifications.service';
import { Logger } from 'src/services/core/logger/logger.service';
import { ProductsService } from 'src/services/products/products.service';

const log = new Logger('FavoriteProduct');

@Component({
  selector: 'app-favorite-product',
  templateUrl: './favorite-product.component.html',
  styleUrls: ['./favorite-product.component.scss'],
})
export class FavoriteProductComponent implements OnInit {
  @Input() product: CartProduct;
  @Input() selectedProductIndex: number;

  constructor(
    private productsService: ProductsService,
    private cartService: CartService,
    private notifyService: NotificationsService
  ) {}

  ngOnInit() {}

  removeItemFromWishlist(product: CartProduct) {
    // if (product.wishlistId) {
    const deleteFromWishlist$ = this.productsService.deleteFromWishlist(
      product.product_id
    );
    deleteFromWishlist$.subscribe(
      (res: any) => {
        if (res.error === false) {
          log.debug('res: ', res);
          this.cartService.removeFromList(this.selectedProductIndex);
          if (res.message) {
            this.notifyService.publishMessages(res.message, 'success', 1);
          }
        } else {
          this.notifyService.publishMessages(res.message, 'success', 1);
        }
      },
      (error: any) => log.debug('error: ', error)
    );
    // }
  }
}
