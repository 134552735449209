import { HttpClient } from '@angular/common/http';
import { BaseService } from '../core/base/base.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CartProduct } from '../classes/models/product';

const routes = {
  allProducts: 'guest/products',
  singleProduct: 'guest/products',
  productsByCategoryId: 'guest/categories',
  productsByBrandId: 'guest/products_by_brand_id',
  newArrivals:'guest/new_arrivals',
  productsBySubCategoryId: 'guest/products_by_product_division',
  productsByChildCategoryId: 'guest/products_by_business_unit_id',
  categoriesWithSubCategories: 'guest/categories',
  cart: 'users/carts',
  wishlist: 'users/wishlist',
  search: 'guest/products/search',
  stripe: 'users/orders/stripe',
  postOrder: 'users/orders',
  review: 'users/review',
  shippingInformation: 'users/orders/shippingfee'
};

@Injectable({
  providedIn: 'root'
})
export class ProductsService extends BaseService<any> {
  constructor(http: HttpClient) {
    super(http);
  }

  getAllProducts(payload: any) {
    return this.sendPost(this.baseUrl(routes.allProducts), payload);
  }

  getAllNewArrivals(payload: any,currentPage = 1) {
    return this.sendPost(this.baseUrl(`${routes.newArrivals}?page=${currentPage}`), payload);

  }
  getAllProductsPerCollection(payload: any, collectionId: any) {
    return this.sendPost(this.baseUrl(`guest/products_by_productcollection/${collectionId}`), payload);

  }

  searchProducts(searchQuery: string, currentPage = 1): Observable<any> {
    return this.sendGet(
      this.baseUrl(`${routes.search}?search=${searchQuery}&page=${currentPage}`)
    );
  }
  viewSingleProductById(id: any) {
    return this.sendGet(this.baseUrl(`${routes.singleProduct}/${id}`));
  }
  getProductsByCategoryId(
    catId: number,
    currentPage: number,
    pageSize: number
  ): Observable<any> {
    return this.sendGet(
      this.baseUrl(
        `${routes.productsByCategoryId}/${catId}?page=${currentPage}`
      )
    );
  }

  getProductsByBrandId(
    brandId: number,
    currentPage: number,
    pageSize: number
  ): Observable<any> {
    return this.sendGet(
      this.baseUrl(
        `${routes.productsByBrandId}/${brandId}?page=${currentPage}`
      )
    );
  }

  getProductsBySubCategoryId(
    subCatId: number,
    currentPage: number,
    pageSize: number
  ): Observable<any> {
    return this.sendGet(
      this.baseUrl(
        `${routes.productsBySubCategoryId}/${subCatId}?page=${currentPage}`
      )
    );
  }

  getProductsByChildCategoryId(
    childId: number,
    currentPage: number,
    pageSize: number
  ): Observable<any> {
    return this.sendGet(
      this.baseUrl(
        `${routes.productsByChildCategoryId}/${childId}?page=${currentPage}`
      )
    );
  }

  createReview(payload: any): Observable<any> {
    return this.sendPost(this.baseUrl(routes.review), payload);
  }

  getCategoriesWithSubcats(): Observable<any> {
    return this.sendGet(this.baseUrl(routes.categoriesWithSubCategories));
  }

  /* Cart Endpoints */
  /*
   * Add to cart after login
   * @param payload { carts: [products] }
   */
  transferToCart(payload: any): Observable<any> {
    return this.sendPost(this.baseUrl(`${routes.cart}/transfer`), payload);
  }

  addToCart(payload: CartProduct): Observable<any> {
    return this.sendPost(this.baseUrl(routes.cart), payload);
  }

  // Increase or reduce product count in cart
  updateCart(cartId: number, payload: CartProduct): Observable<any> {
    return this.sendPut(this.baseUrl(`${routes.cart}/${cartId}`), payload);
  }

  deleteCartItem(cartId: number): Observable<any> {
    return this.sendDelete(this.baseUrl(`${routes.cart}/${cartId}`));
  }

  getCartItems(): Observable<any> {
    return this.sendGet(this.baseUrl(routes.cart));
  }
  /* End Cart Endpoints */

  /* Wishlist Routes */
  getWishlistItems(): Observable<any> {
    return this.sendGet(this.baseUrl(routes.wishlist));
  }

  addToWishlist(payload: CartProduct): Observable<any> {
    return this.sendPost(this.baseUrl(routes.wishlist), payload);
  }

  deleteFromWishlist(wishlistId: number): Observable<any> {
    return this.sendDelete(this.baseUrl(`${routes.wishlist}/${wishlistId}`));
  }
  /* End Wishlist Routes */

  payWithStripe(payload: any) {
    return this.sendPost(this.baseUrl(routes.stripe), payload);
  }

  postOrder(payload: any): Observable<any> {
    return this.sendPost(this.baseUrl(routes.postOrder), payload);
  }

  getShippingFee(): Observable<any> {
    return this.sendGet(this.baseUrl(routes.shippingInformation));
  }
}
