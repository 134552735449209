<div class="row card-wrapper align-items-center">
  <div class="col-12 col-xl-3 col-md-12 col-lg-2">
    <div class="img-select">
      <div class="img-item mb-3" *ngFor="let image of productImages">
        <div class="img-container" (click)="changeImage(image)">
          <img [src]="image" alt="" />
          <div
            class="overlay"
            [class.active]="
              image === currentImage ? showOverlayFalse : showOverlay
            "
          ></div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-12 col-xl-8 col-lg-9">
    <div class="img-display">
      <div class="img-showcase">
        <!-- class="img-fluid" -->
        <!-- style="object-fit:cover; height: auto; width: 100%;" -->
        <img
          [src]="currentImage === '' ? productImages[0] : currentImage"
          alt=""
          class="img-fluid"
          id="main_product_image"
        />
      </div>
    </div>
  </div>
</div>
