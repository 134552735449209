<div class="footer">
  <div class="container-fluid-md container-lg py-5">
    <div class="row">
      <div class="col-md-2">
        <img src="assets/images/logo_white_1.svg" alt="logo" />
      </div>
      <div class="col-md-2 mt-md-0 mt-3">
        <ul class="list-unstyled">
          <li class="mb-4 text-white font-normal pointer f-20">Shop Online</li>
          <li
            routerLink="category/{{ cat?.id }}"
            *ngFor="let cat of categories"
            class="text-dark_grey mb-3 font-normal pointer f-16"
          >
            {{ cat?.cat_name }}
          </li>
        </ul>
      </div>

      <div class="col-md-2 mt-md-0 mt-3">
        <ul class="list-unstyled">
          <li class="mb-4 text-white font-normal pointer f-20">About</li>
          <li
            routerLink="/about"
            class="text-dark_grey mb-2 font-normal pointer f-16"
          >
            Company
          </li>
          <li
            class="text-dark_grey mb-2 font-normal pointer f-16"
            routerLink="/faq"
          >
            FAQ
          </li>
          <li
            routerLink="/locations"
            class="text-dark_grey mb-2 font-normal pointer f-16"
          >
            Stores
          </li>
          <!-- <li class="text-dark_grey font-normal pointer f-16">Investors</li>
          <li class="text-dark_grey font-normal pointer f-16">Careers</li> -->
        </ul>
      </div>
      <div class="col-md-3 mt-md-0 mt-3">
        <ul class="list-unstyled">
          <li class="mb-4 text-white font-normal pointer f-20">Support</li>
          <li
            class="text-dark_grey mb-2 font-normal pointer f-16"
            [routerLink]="['/about', { section: 'footer' }]"
          >
            Contact Us
          </li>
          <li
            routerLink="/return-policy"
            class="text-dark_grey mb-2 font-normal pointer f-16"
          >
            Return Policy
          </li>
          <li
            class="text-dark_grey mb-2 font-normal pointer f-16"
            routerLink="/privacy-policy"
          >
            Privacy Policy
          </li>
          <!-- <li class="text-dark_grey font-normal pointer f-16">Term of Use</li> -->
        </ul>
      </div>
      <div class="col-md-3 mt-md-0 mt-3">
        <h3 class="mb-4 text-white font-normal f-20">News Letter</h3>
        <p class="text-dark_grey font-normal f-16 text-md-left text-center">
          Subscribe now to receive monthly news & personalised offers!
        </p>
        <form [formGroup]="subscribeForm">
          <div class="form-group mt-2">
            <label class="custom-label" for="email">Email</label>
            <input
              formControlName="email"
              type="email"
              id="useremail"
              class="custom-input w-100"
              placeholder="Enter Email"
            />
          </div>

          <div class="mt-2">
            <button
              [disabled]="subscribeForm.invalid"
              (click)="subscribe()"
              class="btn-custom w-75"
            >
              Subscribe Now
            </button>
          </div>
        </form>
        <div class="mt-2">
          <p class="text-dark_grey font-normal f-16 text-md-left text-center">
            Follow us on
            <span class="ml-3 pointer">
              <a (click)="viewLink('https://www.youtube.com/@themixafrica809')">
                <img src="assets/icons/youtube_icon.svg" alt="img" />
              </a>
            </span>
            <span class="ml-3 pointer">
              <a
                (click)="
                  viewLink(
                    'https://www.instagram.com/themix.ng/?igshid=YmMyMTA2M2Y%3D'
                  )
                "
              >
                <img src="assets/icons/instagram_icon.svg" alt="img" />
              </a>
            </span>
            <span class="ml-3 pointer">
              <a (click)="viewLink('https://twitter.com/TheMixAfrica')">
                <img src="assets/icons/twitter_icon.svg" alt="img" />
              </a>
            </span>
            <span class="ml-2 pointer">
              <a
                (click)="
                  viewLink('https://web.facebook.com/themixafrica?_rdc=1&_rdr')
                "
              >
                <img
                  src="assets/icons/facebk.jpeg"
                  width="20"
                  height="20"
                  alt="img"
                />
              </a>
            </span>
          </p>
        </div>
      </div>
    </div>
    <p class="mt-5 text-dark_grey font-normal text-center f-14">
      &copy; {{ year }} SBSC. Persianas All Rights Reserved
    </p>
  </div>
</div>
