enum LogLevel {
  Off = 0,
  Error,
  Warning,
  Info,
  Debug
}
export const environment = {
  production: true,
  productLogoLarge: 'assets/images/logo-dark.svg',
  serverUrl:
    'https://prlerp.com/peresianas-BE/public/api/v1/',
  flwPubKey: 'FLWPUBK_TEST-daf77b348cd99161b3110384d16d9b38-X',
  // paystackPubKey: 'pk_live_d2c573f019916ee59c5d43a8551aea8e2518ba6b',
  paystackPubKey: 'pk_test_eb2d1907b3523dc5d7caf6155da23cbf0f7b2026',
  logLevel: LogLevel.Off
};
