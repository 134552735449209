import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '../core/base/base.service';
import { Observable } from 'rxjs';

const routes = {
  categories: 'guest/categoriesonly',
  otherCategories: 'guest/categories/other',
  allCategories:"guest/categories_info",
  colors: 'guest/product/color',
  size: 'guest/product/size',
  children: 'guest/children',
  subscribe: 'guest/subscribe',
  collections:'guest/all_product_collection',
  brands:"guest/all_brands"
};
@Injectable({
  providedIn: 'root'
})
export class HomeService extends BaseService<any> {
  constructor(http: HttpClient) {
    super(http);
  }

  getCategories() {
    return this.sendGet(this.baseUrl(routes.categories));
  }
  getBrands() {
    return this.sendGet(this.baseUrl(routes.brands));
  }

  subscribe(payload: any): Observable<any> {
    return this.sendPost(this.baseUrl(routes.subscribe), payload);
  }

  getChildrenOfSubcategory(sub_cat_id: any) {
    return this.sendGet(this.baseUrl(`${routes.children}/${sub_cat_id}/sub-category`));
  }
  getAllCategoriesInfo():Observable<any>{
    return this.sendGet(this.baseUrl(routes.allCategories));
  }

  getAllColors(){
    return this.sendGet(this.baseUrl(routes.colors));

  }


  getAllCollections(){
    return this.sendGet(this.baseUrl(routes.collections));

  }
  getAllSizes(){
    return this.sendGet(this.baseUrl(routes.size));
  }

  getPrivacyPolicy(): Observable<any> {
    return this.sendGet(this.baseUrl(`guest/privacypolicy`));
  }

  getReturnPolicy(): Observable<any> {
    return this.sendGet(this.baseUrl(`guest/returnpolicy`));
  }

  getFaq(): Observable<any> {
    return this.sendGet(this.baseUrl(`guest/faq`));
  }

  getFaqSetUp(): Observable<any> {
    return this.sendGet(this.baseUrl(`guest/faqsetup`));
  }

  getStores(): Observable<any> {
    return this.sendGet(this.baseUrl(`guest/allstores`));
  }

  getCompany(): Observable<any> {
    return this.sendGet(this.baseUrl(`guest/aboutus`));
  }

  getLandingData(): Observable<any> {
    return this.sendGet(this.baseUrl(`guest/landingpage`));
  }

  getNewArrivals(): Observable<any> {
    return this.sendPost(this.baseUrl(`guest/new_arrivals`), {});
  }

  getCollectionById(id: any): Observable<any> {
    return this.sendGet(this.baseUrl(`guest/collection/individual/${id}`));
  }

  fetchArticleGroups(): Observable<any> {
    return this.sendGet(this.baseUrl(`guest/all_article_groups`));
  }

}
