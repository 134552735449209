import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Category } from 'src/services/classes/models/category';
import { NotificationsService } from 'src/services/classes/notifications/notifications.service';
import { Logger } from 'src/services/core/logger/logger.service';
import { HomeService } from 'src/services/home/home.service';
const log = new Logger('footer');
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  categories: Category[] = [];
  subscribeForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });
  year = new Date().getFullYear();

  constructor(
    private homeService: HomeService,
    private notifyService: NotificationsService,
  ) { }

  ngOnInit() {
    this.getCategoriesToSetupForMain();
  }

  getCategoriesToSetupForMain() {
    const savedCategories = localStorage.getItem('categories');
    const savedSubCategories = localStorage.getItem('subCat');
    if (savedCategories === null || savedSubCategories === null) {
      const categoriesForMain$ = this.homeService.getCategories();
      categoriesForMain$.subscribe(
        (res: any) => {
          if (res.error === false) {
            const response = res.data;
            this.categories = response;
            if (response) {
              localStorage.setItem(
                'categories',
                JSON.stringify(this.categories)
              );
         
            }
        
            this.setupMain();
          } else {
            this.notifyService.publishMessages(res.message, 'danger', 1);
          }
        },
        error => {
          log.debug('error: ', error);
        }
      );
    } else {
      this.setupMain();
    }
  }

  get f() {
    return this.subscribeForm.controls;  
  }

  setupMain() {
    this.categories = JSON.parse(localStorage.getItem('categories'));
}

  subscribe = () => {
    this.homeService.subscribe(this.subscribeForm.value).subscribe(res => {
      if (!res.error) {
        this.notifyService.publishMessages(res.message, 'success', 1);
      } else {
        this.notifyService.publishMessages(res.message, 'danger', 1);
      }
    }, err => {
      this.notifyService.publishMessages(err.message, 'danger', 1);
    })
  }

  viewLink = (url: string) => {
    window.open(url, '_blank')
  }

}
