<div
  *ngIf="notification.show"
  aria-live="polite"
  class="font-weight-medium"
  aria-atomic="true"
  style="position: fixed; top: 10px; right: 0; left: 0; z-index: 34444444444444444444444; font-family: 'AtypDisplay-Medium', sans-serif;"
>
  <div
    class="text-center alert alert-{{ notification.style }} bounce"
    role="alert"
  >
    {{ notification.content }}
    <i
      (click)="dismiss()"
      class="fa fa-times ml-2 border cursor-pointer border-{{
        notification.style
      }} rounded-circle p-1"
    ></i>
  </div>
</div>
