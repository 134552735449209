import { TrustedURLPipe } from './../services/pipes/trustedURL/trusted-urlpipe.pipe';
import { NumberFormatPipe } from './../services/pipes/negativeNumber/negative-number.pipe';
import { HumanizePipe } from './../services/pipes/humanize/humanize.pipe';
import { AbbPipe } from './../services/pipes/abbriviate/abb.pipe';
import { DragDropDirective } from './../services/directives/dragdrop/dragdrop.directive';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { HttpErrorInterceptor } from 'src/services/interceptors/Base/http-interceptor';
import { TokenInterceptor } from 'src/services/interceptors/Token/token';

@NgModule({
  declarations: [
    AppComponent,
    DragDropDirective,
    AbbPipe,
    HumanizePipe,
    NumberFormatPipe,
    TrustedURLPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    BrowserAnimationsModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
