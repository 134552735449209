import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, tap, finalize, delay } from 'rxjs/operators';

import { LoaderService } from '../../classes/loader/loader.service';
import { NavigationEnd, Router } from '@angular/router';
import { NotificationsService } from '../../classes/notifications/notifications.service';
import { Logger } from 'src/services/core/logger/logger.service';
import { CartService } from 'src/services/cart/cart.service';
import { CredentialsService } from 'src/app/authentication/credentials/credentials.service';
import { filter } from 'rxjs/operators';

const log = new Logger('Int!');

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  errorMessage: string;
  currentRoute: any = null;
  constructor(
    private loaderService: LoaderService,
    private router: Router,
    private notification: NotificationsService,
    private cartService: CartService,
    private credentialsService: CredentialsService
  ) {
    router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.currentRoute = event.url;
      });
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      ['POST'].includes(request.method) &&
      (request.url.endsWith('carts') || request.url.endsWith('wishlist'))
    ) {
      this.loaderService.hide();
    } else if (
      ['DELETE', 'PUT'].includes(request.method) &&
      request.url.includes('carts')
    ) {
      this.loaderService.hide();
    } else if (
      ['DELETE', 'PUT'].includes(request.method) &&
      request.url.includes('wishlist')
    ) {
      this.loaderService.hide();
    } else if (request.url.includes('logout')) {
      this.loaderService.hide();
    } else if (
      request.url.includes('users/wishlist') &&
      request.method === 'GET'
    ) {
      this.loaderService.hide();
    } else if (
      request.url.includes('users/carts') &&
      request.method === 'GET'
    ) {
      this.loaderService.hide();
    } else if (
      request.url.includes('guest/categories_info') ||
      request.url.includes('guest/categoriesonly') ||
      request.url.includes('guest/all_brands') ||
      request.url.includes('guest/all_product_collection')
    ) {
      this.loaderService.hide();
    } else {
      this.loaderService.show();
    }

    return next.handle(request).pipe(
      tap((evt) => {
        // custom api errors
        if (evt instanceof HttpResponse) {
          this.loaderService.hide();
          if (evt.body !== null) {
            if (evt.body.error == true) {
              this.errorMessage = evt.body.message;
              if (evt.body.message === 'Unauthenticated.') {
                this.logout();
                this.notification.publishMessages(
                  'Please log in',
                  'warning',
                  1
                );
              }
            }
          }
        }
      }),

      catchError((error: HttpErrorResponse) => {
        this.loaderService.hide();
        log.debug(error);

        if (error.error instanceof ErrorEvent) {
          // client-side error
          this.errorMessage = `Error: ${error.error.message}`;
        } else {
          // server-side error

          switch (error.status) {
            case 503: {
              this.errorMessage =
                'An Internal Error Occurred. Our Engineers Have Been Contacted';
              this.notification.publishMessages(this.errorMessage, 'danger', 1);
              break;
            }
            case 500: {
              this.errorMessage =
                'An Internal Error Occurred. Our Engineers Have Been Contacted';
              this.notification.publishMessages(this.errorMessage, 'danger', 1);
              break;
            }
            case 400: {
              const errorMessage = error.error.message;
              this.notification.publishMessages(errorMessage, 'danger', 1);
              break;
            }
            case 404: {
              this.errorMessage =
                'An Error Occurred While Processing Your Request. Please Try Again';
              this.notification.publishMessages(this.errorMessage, 'danger', 1);
              break;
            }
            case 401: {
              this.notification.publishMessages(
                'Session timed out',
                'danger',
                1
              );
              this.logout();
              break;
            }
            case 405: {
              this.errorMessage =
                'An Error Occurred While Processing Your Request. Please Try Again';
              this.notification.publishMessages(this.errorMessage, 'danger', 1);
              break;
            }
            case 0: {
              this.errorMessage =
                'A Connection Error Occurred. Please Check Your Network Connection';
              this.notification.publishMessages(this.errorMessage, 'danger', 1);
              break;
            }
          }
        }

        return throwError(error.error);
      })
    );
  }

  logout() {
    this.cartService.clearCart();
    this.cartService.clearWishlist();
    this.credentialsService.setCredentials();
    this.router.navigate(['/auth'], {
      queryParams: { returnUrl: this.currentRoute },
    });
  }
}
