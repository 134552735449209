<div *ngIf="!forCart">
  <div class="card d-flex align-items-center flex-row border-0">
    <div class="w-30">
      <img
        *ngIf="product?.image"
        [src]="product?.image"
        [alt]="product?.product_name"
        class="rounded"
        width="120"
        height="120"
      />
      <img
        *ngIf="!product?.image"
        src="assets/images/perisiana.png"
        [alt]="product?.product_name"
        width="120"
        height="120"
        class="rounded"
      />
    </div>
    <div class="mx-4 w-60">
      <p class="mb-2 font-weight-medium">{{ product?.product_name }}</p>
      <div class="mb-2 d-flex justify-content-between">
        <p class="mb-0 txt-gy">
          Size {{ product.size ? product.size : '--' }} . QTY
          {{ product?.quantity_ordered }}
        </p>
        <div class="row align-self-end" *ngIf="product?.color">
          <p class="mb-0 txt-gy">
            {{ product?.color }}
          </p>
          <!-- <div
            [ngStyle]="{
              'background-color': product?.color?.toLowerCase()
            }"
            class="rounded-circle color-border color mr-1"
          ></div> -->
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <p class="mb-0 font-weight-medium">
          {{ product?.unit_price | currency : '₦' }}
        </p>
        <div class="d-flex align-items-center">
          <img src="assets/icons/rating-star.svg" alt="rating-star" /> &nbsp;
          <span>{{ product?.ratings }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="forCart">
  <div class="card d-flex align-items-start flex-row border-0">
    <div class="w-30">
      <img
        [src]="product?.image"
        [alt]="product?.product_name"
        width="120"
        height="120"
      />
    </div>
    <div class="mx-4 w-60">
      <p class="mb-2 font-weight-medium">{{ product?.product_name }}</p>
      <div class="mb-2 d-flex justify-content-between">
        <p class="mb-0 txt-gy">
          Size {{ product.size ? product.size : '--' }} . QTY
          {{ product?.quantity_ordered }}
        </p>
        <!-- This should be the selected color from when the user added the product to cart -->
        <div class="row align-self-end" *ngIf="product?.color">
          <p class="mb-0 txt-gy">
            {{ product?.color }}
          </p>
          <!-- <div
            [ngStyle]="{
              'background-color': product?.color?.toLowerCase()
            }"
            class="rounded-circle color-border color mr-1"
          ></div> -->
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <p class="mb-0 font-weight-medium">
          {{ product?.unit_price | currency : '₦' }}
        </p>
        <div class="d-flex align-items-center">
          <img src="assets/icons/rating-star.svg" alt="rating-star" /> &nbsp;
          <span>{{ product?.ratings }}</span>
        </div>
      </div>
    </div>
    <div>
      <img
        (click)="removeFromCart(product)"
        src="assets/icons/close-icn.svg"
        alt="close"
        class="pointer"
      />
    </div>
  </div>
  <div class="row mt-3 align-items-center">
    <div class="col-6">
      <div class="form-group mb-0">
        <select
          (change)="selectSize($event, product)"
          class="custom-input w-100"
        >
          <option selected>Choose size</option>
          <option
            *ngFor="let size of product.sizes"
            [value]="size || product?.size"
          >
            {{ size }}
          </option>
        </select>
      </div>
    </div>
    <div class="col-6">
      <div class="count-toggler">
        <span (click)="reduceCount(product)" class="mb-1 pointer">
          <img src="assets/icons/minus-cirlce.svg" alt="reduce" />
        </span>
        <p class="mb-0 p-0">{{ product?.quantity_ordered }}</p>
        <span (click)="increaseCount(product)" class="mb-1 pointer">
          <img src="assets/icons/add-circle.svg" alt="increase" />
        </span>
      </div>
    </div>
  </div>
</div>
