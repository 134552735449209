import { CredentialsService } from 'src/app/authentication/credentials/credentials.service';
import {
  Component,
  OnInit,
  EventEmitter,
  HostListener,
  Output,
  ElementRef,
  Input,
  ViewChild,
  Renderer2,
  OnDestroy,
  NgZone,
} from '@angular/core';
import * as moment from 'moment';

import { FormBuilder, FormGroup } from '@angular/forms';
import { Category } from 'src/services/classes/models/category';
import { NotificationsService } from 'src/services/classes/notifications/notifications.service';
import { Logger } from 'src/services/core/logger/logger.service';
import { HomeService } from 'src/services/home/home.service';
import { AuthService } from 'src/services/data/auth/auth.service';
import { CartService } from 'src/services/cart/cart.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ProductsService } from 'src/services/products/products.service';
import { Subscription, interval } from 'rxjs';
import { Product } from 'src/services/classes/models/product';

const log = new Logger('Cart');

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild('menuDiv', { static: true }) menuDiv: ElementRef;

  showSearchBar = false;
  searchForm: FormGroup;
  innerWidth: any;
  showSearchBarMobile = false;
  dropped = false;
  isCollection = false;
  isDrawerOpen = false;
  isOthers = false;
  isBrand = false;
  currentRoute: any;
  @Output() openDrawerFromHeader = new EventEmitter<string>();
  @Output() openFavoritesFromHeader = new EventEmitter<string>();
  categories: Category[] = [];
  subcategories: Array<any> = [];
  allSubcategories: Array<any> = [];
  collections: Array<any> = [];
  brands: Array<any> = [];
  categoriesList: Array<any> = [];
  allChildrenCategories: any[] = [];
  categorySelected: any = {};
  cartTotal = 0;
  wishlistTotal = 0;
  private storageChangeSubscription: Subscription;

  constructor(
    private fb: FormBuilder,
    private elementRef: ElementRef,
    private homeService: HomeService,
    private credentialsService: CredentialsService,
    private notifyService: NotificationsService,
    private authService: AuthService,
    private cartService: CartService,
    private router: Router,
    private productsService: ProductsService,
    private ngZone: NgZone
  ) {
    router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.currentRoute = event.url;
      });
  }
  // ngAfterViewInit() {
  //   console.log('after',this.menuDiv?.nativeElement);
  // }

  ngOnInit() {
    this.initSearchForm();
    this.getCategoriesToSetupForMain();
    this.setupAutoRefresh();
    this.fetchCategoryAndSetupForHeader();
    this.fetchCollectionndSetupForHeader();
    this.fetchBrandAndSetupForHeader();

    // Listen for changes in session storage using interval and ngZone to trigger change detection
    this.storageChangeSubscription = interval(1000).subscribe(() => {
      this.ngZone.run(() => {
        // log.debug('cartho', sessionStorage.getItem('cart'))
        const cartNumber = JSON.parse(sessionStorage.getItem('cart')) || [];
        const wishListNumber =
          JSON.parse(sessionStorage.getItem('wishList')) || [];
        this.cartTotal = cartNumber.length;
        this.wishlistTotal = wishListNumber.length;

        // this.currentValue = sessionStorage.getItem('currentValue') || '0';
      });
    });
  }

  // updateCartNumberFromSessionStorage(event: StorageEvent) {
  //   log.debug('current2', event.newValue)
  //   if (event.key === 'cart') {
  //     log.debug('current', event.newValue)
  //     // this.cartTotal = event.newValue || '0';
  //   }
  // }

  ngOnDestroy() {
    this.storageChangeSubscription.unsubscribe();
  }
  goToArrivals() {
    this.dropped = false;
    this.router.navigateByUrl('/new-arrivals');
  }

  fetchAllCategoriesInfo() {
    this.homeService.getAllCategoriesInfo().subscribe((res) => {
      if (res.error == false) {
        localStorage.setItem('categoriesList', JSON.stringify(res.data));
        this.categoriesList = res.data;
      } else {
        this.notifyService.publishMessages(res.message, 'danger', 1);
      }
    });
  }

  fetchAllCollections() {
    this.homeService.getAllCollections().subscribe((res) => {
      if (res.error == false) {
        localStorage.setItem('collectionList', JSON.stringify(res.data));
        this.collections = res.data;
      } else {
        this.notifyService.publishMessages(res.message, 'danger', 1);
      }
    });
  }

  fetchAllBrands() {
    this.homeService.getBrands().subscribe((res) => {
      if (res.error == false) {
        localStorage.setItem('brandList', JSON.stringify(res.data));
        this.brands = res.data;
      } else {
        this.notifyService.publishMessages(res.message, 'danger', 1);
      }
    });
  }

  fetchCollectionndSetupForHeader() {
    const savedCollections = localStorage.getItem('collectionList');
    if (savedCollections == null) {
      this.fetchAllCollections();
    } else {
      this.collections = JSON.parse(localStorage.getItem('collectionList'));
    }
  }

  fetchBrandAndSetupForHeader() {
    const savedBrands = localStorage.getItem('brandList');
    if (savedBrands == null) {
      this.fetchAllBrands();
    } else {
      this.brands = JSON.parse(localStorage.getItem('brandList'));
    }
  }

  fetchCategoryAndSetupForHeader() {
    const savedCategories = localStorage.getItem('categoriesList');
    if (savedCategories == null) {
      this.fetchAllCategoriesInfo();
    } else {
      this.categoriesList = JSON.parse(localStorage.getItem('categoriesList'));
      localStorage.setItem('categoryExpiryTime', String(this.inTwoMinutes()));
    }
  }

  fetchDropDownData(value: string) {
    this.isOthers = true;
    this.isCollection = false;
    this.isBrand = false;
    this.categoriesList.forEach((element) => {
      if (element.slug.toLowerCase() === value.toLocaleLowerCase()) {
        // this.currentCategories = element
        console.log('current item', element);
        this.categorySelected = element;
        this.dropped = !this.dropped;
      }
    });
  }

  fetchDropDownDataCollection() {
    this.isCollection = true;
    this.isBrand = false;
    this.isOthers = false;
    this.collections = JSON.parse(localStorage.getItem('collectionList'));
    this.dropped = !this.dropped;
  }

  fetchDropDownDataBrand() {
    this.isCollection = false;
    this.isBrand = true;
    this.isOthers = false;
    this.brands = JSON.parse(localStorage.getItem('brandList'));
    this.dropped = !this.dropped;
  }

  setupAutoRefresh() {
    const interval = setInterval(() => {
      this.doAutoRefreshForCategoriesData();
      this.getCartItemsFromDB();
      this.getWishlistItemsFromDB();
    }, 300000);
  }

  inTwoMinutes() {
    return moment().add(2, 'm').toDate().getTime();
  }

  doAutoRefreshForCategoriesData() {
    if (localStorage.getItem('categoryExpiryTime')) {
      const expiryTime = localStorage.getItem('categoryExpiryTime');
      const timeNow = new Date().getTime();
      if (timeNow >= parseFloat(expiryTime)) {
        this.fetchAllCategoriesInfo();
        this.fetchAllBrands();
        this.fetchAllCollections();
        log.debug('Doing the thing now!', timeNow, expiryTime);
      }
    }
  }

  isLoggedIn() {
    return this.credentialsService.isAuthenticated();
  }

  credentials() {
    return this.credentialsService.credentials;
  }

  initSearchForm() {
    this.searchForm = this.fb.group({
      search: [''],
    });
  }

  goToRoute(route: string) {
    if (this.isDrawerOpen) {
      this.isDrawerOpen = false;
    }

    this.router.navigateByUrl(route);
  }

  search(event: any) {
    const value = event.target.value;
    if (this.isDrawerOpen) {
      this.isDrawerOpen = false;
    }

    if (value === '') {
      this.notifyService.publishMessages(
        'Please enter a value to search',
        'warning',
        1
      );
    } else {
      this.searchForm.reset();
      sessionStorage.setItem('searchParam', value);
      if (this.currentRoute !== '/search') {
        this.router.navigate(['/', 'search']);
      } else {
        this.router.navigateByUrl('/').then(() => {
          this.router.navigate(['/', 'search']);
        });
      }
    }
  }

  getCategoriesToSetupForMain(resetCategoriesData = false) {
    const savedCategories = localStorage.getItem('categories');
    const savedSubCategories = localStorage.getItem('subCat');
    const savedChildCategories = localStorage.getItem('childCat');
    if (
      savedCategories === null ||
      savedSubCategories === null ||
      savedChildCategories ||
      resetCategoriesData === true
    ) {
      const categoriesForMain$ = this.homeService.getCategories();
      categoriesForMain$.subscribe(
        (res: any) => {
          if (res.error === false) {
            log.debug('cat-res: ', res.data);
            const response = res.data;
            this.categories = response;
            if (response) {
              localStorage.setItem(
                'categories',
                JSON.stringify(this.categories)
              );
              // this.categories.forEach(element => {
              //   log.debug('categories,', this.categories);
              //   // @ts-ignore
              //   this.allSubcategories.push(...element.subcategory);
              //   log.debug('subcategories,', this.allSubcategories);

              //   // @ts-ignore
              //   this.allChildrenCategories.push(...element.children);
              //   log.debug('children: ', this.allChildrenCategories);
              // });
            }
            if (this.allSubcategories.length > 0) {
              localStorage.setItem(
                'subCat',
                JSON.stringify(this.allSubcategories)
              );
            }
            if (this.allChildrenCategories.length > 0) {
              localStorage.setItem(
                'childCat',
                JSON.stringify(this.allChildrenCategories)
              );
            }
            localStorage.setItem(
              'categoryExpiryTime',
              String(this.inTwoMinutes())
            );
            this.setupMain();
          } else {
            this.notifyService.publishMessages(res.message, 'danger', 1);
          }
        },
        (error) => {
          log.debug('error: ', error);
        }
      );
    } else {
      this.setupMain();
    }
  }

  setupMain() {
    this.categories = JSON.parse(localStorage.getItem('categories'));
    this.allSubcategories = JSON.parse(localStorage.getItem('subCat'));
    this.allChildrenCategories = JSON.parse(localStorage.getItem('childCat'));

    log.debug('main done!');
  }
  getAllCategories() {
    this.homeService.getCategories().subscribe((res) => {
      log.debug('categories:', res);
    });
  }

  setShowSearchBar() {
    if (this.showSearchBar) {
      this.showSearchBar = false;
    } else {
      this.showSearchBar = true;
    }
  }
  setShowDropped(id) {
    this.subcategories = [];
    this.allSubcategories.forEach((element) => {
      if (element.cat_id == id) {
        this.subcategories.push(element);
      }
    });
    this.dropped = !this.dropped;
  }

  getMobileDropdown(value, number) {
    if (this.categoriesList.length === 0) {
      this.fetchCategoryAndSetupForHeader();
    }
    this.categoriesList.forEach((element) => {
      if (element.slug.toLowerCase() === value.toLocaleLowerCase()) {
        log.debug('current element', element);
        // this.currentCategories = element
        this.categorySelected = element;
        // this.dropped = !this.dropped
      }
    });
    if (number == 1) {
      document.getElementById('getmobileCOllapse1').click();
    } else if (number == 2) {
      document.getElementById('getmobileCOllapse2').click();
    } else {
      document.getElementById('getmobileCOllapse3').click();
    }
  }

  getMobileDropdownForCollection() {
    if (this.collections.length === 0) {
      this.fetchCollectionndSetupForHeader();
    }
    this.collections = JSON.parse(localStorage.getItem('collectionList'));
    document.getElementById('getmobileCOllaps4')?.click();
  }

  getMobileDropdownForBrands() {
    if (this.brands.length === 0) {
      this.fetchBrandAndSetupForHeader();
    }
    this.brands = JSON.parse(localStorage.getItem('brandList'));

    log.debug('here', this.brands);

    document.getElementById('getmobileCOllapse5')?.click();
  }

  openFavorites() {
    this.isDrawerOpen = false;
    this.openFavoritesFromHeader.emit('open-favorites');
  }

  openCart() {
    this.isDrawerOpen = false;
    this.openDrawerFromHeader.emit('open-cart');
  }
  openDrawer() {
    this.isDrawerOpen = true;
  }

  closeDrawer(event: string) {
    if (event === 'close-drawer') {
      this.isDrawerOpen = false;
    }
  }
  setShowSearchBarMobile() {
    this.showSearchBarMobile = !this.showSearchBarMobile;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth > 995) {
      this.isDrawerOpen = false;
    }
    if (this.innerWidth < 995) {
      this.dropped = false;
    }
  }

  logout() {
    const logout$ = this.authService.logout();
    logout$.subscribe(
      (res: any) => {
        if (res.error === false) {
          this.logoutManually();
        } else {
          this.logoutManually();
        }
      },
      (error: any) => this.logoutManually()
    );
  }

  logoutManually() {
    this.cartService.clearCart();
    this.cartService.clearWishlist();
    this.credentialsService.setCredentials();
    document.getElementById('dismissLogoutModal').click();
    if (this.isDrawerOpen) {
      this.isDrawerOpen = false;
    }
    this.router.navigate(['/']);
  }

  saveDetails(categoryDetails) {
    const details = {
      cat_id: categoryDetails.cat_id,
      division_id: categoryDetails.id,
      division_name: categoryDetails.name,
    };
    sessionStorage.setItem('details', JSON.stringify(details));
    this.dropped = false;
    if (this.isDrawerOpen) {
      this.isDrawerOpen = false;
    }
  }

  saveDetailsProductDivisions(business_unit, productDivisions) {
    // sessionStorage.setItem('details', JSON.stringify(categoryDetails));
    // sessionStorage.setItem('productDivisions', JSON.stringify(productDivisions));
    const details = {
      cat_id: business_unit.cat_id,
      division_id: productDivisions.id,
      division_name: productDivisions.name,
      business_unit_id: business_unit.id,
      business_unit_name: business_unit.name,
    };
    sessionStorage.setItem('details', JSON.stringify(details));

    this.dropped = false;
    if (this.isDrawerOpen) {
      this.isDrawerOpen = false;
    }
  }

  saveDetailsCollection(collection: any) {
    sessionStorage.setItem('Collectiondetails', JSON.stringify(collection));
    this.dropped = false;
    if (this.isDrawerOpen) {
      this.isDrawerOpen = false;
    }
  }

  saveDetailsBrand(brand: any) {
    sessionStorage.setItem('brandDetails', JSON.stringify(brand));
    this.dropped = false;
    if (this.isDrawerOpen) {
      this.isDrawerOpen = false;
    }
  }

  // @HostListener('document:click', ['$event'])
  // onDocumentClick(event: MouseEvent) {
  //   log.debug("native",this.menuDiv.nativeElement)
  //   if (this.menuDiv && !this.menuDiv.nativeElement.contains(event.target)) {
  //       this.dropped = false
  //   }
  // }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const clickedInsideSection = this.elementRef.nativeElement.contains(
      event.target
    );
    const clickedShowButton = (event.target as HTMLElement).classList.contains(
      'show-button'
    );

    if (!clickedInsideSection && !clickedShowButton) {
      if (this.dropped) {
        this.dropped = !this.dropped;
      }
    }
  }

  // ngAfterViewInit() {
  //   this.ngZone.runOutsideAngular(() => {
  //     this.renderer.listen('document', 'click', (event: MouseEvent) => {
  //       this.ngZone.run(() => {
  //         if (this.menuDiv && !this.menuDiv.nativeElement.contains(event.target)) {
  //           this.dropped = false;
  //         }
  //       });
  //     });
  //   });
  // }

  extractColorsAndSizes(product: Product) {
    let sizes = [];
    let availableColors = [];
    if (product.product_variation) {
      sizes = product.product_variation.map((variation: any) => {
        if (variation.product_size) {
          return variation.product_size.size;
        }
      });
      availableColors = product.product_variation.map((variation: any) => {
        if (variation.product_color) {
          return variation.product_color.color;
        }
      });
    }
    return {
      sizes,
      availableColors,
    };
  }

  getCartItemsFromDB() {
    const cartItemsFromDB$ = this.productsService.getCartItems();
    cartItemsFromDB$.subscribe(
      (res: any) => {
        if (res.error === false) {
          const cartItems: any[] = res.data;
          log.debug('res-CART: ', res.data);
          if (cartItems !== null) {
            this.cartService.clearCart();
            cartItems.forEach((element) => {
              if (element.product !== null) {
                if (element.product.product_image.includes('|')) {
                  element.product.product_image =
                    element.product.product_image.split('|');
                } else {
                  element.product.product_image = element.product.product_image;
                }

                const colorsAndSizes = this.extractColorsAndSizes(
                  element.product
                );
                let color = '';
                let size = '';
                if (
                  element.product.product_variation &&
                  element.product.product_variation.length > 0
                ) {
                  const { product_color, product_size } =
                    element.product.product_variation[0];
                  if (product_color && product_color.color !== null) {
                    color = product_color.color.toLowerCase();
                  } else {
                    color =
                      element.product.product_variation[0].color.toLowerCase();
                  }
                  if (product_size && product_size.size !== null) {
                    size = product_size.size;
                  } else {
                    size = element.product.product_variation[0].size;
                  }
                }

                element.product.product_id = element.product.id;
                element.product.cartId = element.product.cartId || element.id;
                element.product.selectedPrice = element.price;
                element.product.quantity_ordered = element.quantity;
                element.product.brand_name = element.product.brand.brand_name;

                element.product.size = size;
                element.product.color = color;

                const productToAdd = { ...element.product, ...colorsAndSizes };

                this.cartService.addToCart(
                  productToAdd,
                  element.quantity,
                  color
                );
              }
            });
          } else {
            log.debug('nothing in cart from server');
          }
        } else {
          this.notifyService.publishMessages(res.message, 'danger', 1);
        }
      },
      (error) => log.debug('error: ', error)
    );
  }

  getWishlistItemsFromDB() {
    const wishlistItems$ = this.productsService.getWishlistItems();
    wishlistItems$.subscribe(
      (res: any) => {
        if (res.error === false || res.success === true) {
          const wishlistItems: any[] = res.data;
          log.debug('w-items: ', wishlistItems);
          if (wishlistItems !== null) {
            wishlistItems.forEach((element) => {
              if (element.product !== null) {
                if (element.product.product_image.includes('|')) {
                  element.product.image =
                    element.product.product_image.split('|');
                  element.product.image = element.product.featured_image;
                } else {
                  element.product.image = element.product.product_image;
                }

                element.product.product_id = element.product.id;
                element.product.wishlistId = element.id;
                element.product.selectedPrice = element.price;
                element.product.quantity_ordered = element.quantity;
                element.product.brand_name = element.product.production_company;
                this.cartService.addToWishList(element.product, 1);
                log.debug('added to wishlist in main: ', element.product);
              }
            });
          } else {
            log.debug('nothing in wishlist from server');
            // location.reload();
          }
        } else {
          this.notifyService.publishMessages(res.message, 'danger', 1);
        }
      },
      (error) => log.debug('error: ', error)
    );
  }
}
