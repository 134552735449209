import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment.prod';
import { CartService } from 'src/services/cart/cart.service';
import { CartProduct } from 'src/services/classes/models/product';
import { Logger } from 'src/services/core/logger/logger.service';

const log = new Logger('App');
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isDrawerOpen = false;
  isFavoritesDrawerOpen = false;
  showHeaderAndFooter = false;
  drawerHeader = 'Bag';
  favoritesDrawerHeader = 'Saved Items';
  title = 'TheMix';
  cartItems: CartProduct[] = [];
  cartTotal = 0;
  wishlist: CartProduct[] = [];
  wishTotal = 0;
  totalShippingFees = 0;

  constructor(private router: Router, private cartService: CartService) {}

  ngOnInit(): void {
    if (environment.production) {
      Logger.enableProductionMode();
    }
  }

  closeDrawer(event: string) {
    if (event === 'close-drawer') {
      this.isDrawerOpen = false;
    }
  }

  closeFavoritesDrawer(event: string) {
    if (event === 'close-drawer') {
      this.isFavoritesDrawerOpen = false;
    }
  }

  openFavorites(event: string) {
    this.getCartItems();
    if (event === 'open-favorites') {
      this.isFavoritesDrawerOpen = true;
    }
  }

  openCart(event: string) {
    this.getCartItems();
    if (event === 'open-cart') {
      this.isDrawerOpen = true;
    }
  }

  goToCheckout() {
    this.isDrawerOpen = false;
    this.router.navigateByUrl('/checkout');
  }

  getCartItems() {
    this.cartService.cartUpdate.subscribe(res => {
      // log.debug('res-cart: ', res);
      if (res.cartUpdate || res.listUpdate) {
        if (sessionStorage.getItem('cart')) {
          this.cartItems = JSON.parse(sessionStorage.getItem('cart'));
          this.totalShippingFees = this.cartItems.reduce(
            (accumulator, currentValue) =>
              parseFloat(String(accumulator)) +
              parseFloat(String(currentValue.shipping_fee)),
            0
          );
          this.cartTotal = JSON.parse(sessionStorage.getItem('cartTotal'));
        }

        if (sessionStorage.getItem('wishList')) {
          this.wishlist = JSON.parse(sessionStorage.getItem('wishList'));
          this.wishTotal = JSON.parse(sessionStorage.getItem('wishTotal'));
          // this.getProductsInWishlist();
        }
      } else {
        if (sessionStorage.getItem('cart')) {
          this.cartItems = JSON.parse(sessionStorage.getItem('cart'));
          this.totalShippingFees = this.cartItems.reduce(
            (accumulator, currentValue) =>
              parseFloat(String(accumulator)) +
              parseFloat(String(currentValue.shipping_fee)),
            0
          );
          this.cartTotal = JSON.parse(sessionStorage.getItem('cartTotal'));
        }

        if (sessionStorage.getItem('wishList')) {
          this.wishlist = JSON.parse(sessionStorage.getItem('wishList'));
          this.wishTotal = JSON.parse(sessionStorage.getItem('wishTotal'));
          // We will use this method to differentiate products already in the wishlist
          // this.getProductsInWishlist();
        }
      }
    });
  }

  // getProductsInWishlist() {
  //   if (this.isLoggedIn) {
  //     const allProductsInWishlist = JSON.parse(
  //       sessionStorage.getItem("wishList")
  //     );
  //     if (allProductsInWishlist !== null) {
  //       this.wishListProductIds = allProductsInWishlist.map(
  //         (product: any) => product.product_id
  //       );
  //     }
  //   }
  // }

  goToProducts() {
    this.isDrawerOpen = false;
    this.router.navigateByUrl('/products');
  }

  onActivate(event: any) {
    if (event) {
      this.showHeaderAndFooter = true;
    }
  }
}
