import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './loader/loader.component';
import { AlertComponent } from './alert/alert.component';
import { ServicesModule } from 'src/services/services.module';
import { FooterComponent } from './footer/footer.component';
import { ProductDetailCardComponent } from './product-detail-card/product-detail-card.component';
import { ProductDetailNavbarComponent } from './product-detail-navbar/product-detail-navbar.component';
import { RaterComponentComponent } from './rater-component/rater-component.component';
import { FeaturedProductsComponent } from './featured-products/featured-products.component';
import { DrawerComponent } from './drawer/drawer.component';
import { HeaderComponent } from './header/header.component';
import { ProductComponent } from './product-card/product-card.component';
import { CartProductComponent } from './cart-product/cart-product.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { FilterComponent } from './filter/filter.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { FavoriteProductComponent } from './favorite-product/favorite-product.component';

@NgModule({
  declarations: [
    AlertComponent,
    LoaderComponent,
    FooterComponent,
    DrawerComponent,
    ProductComponent,
    HeaderComponent,
    ProductDetailCardComponent,
    ProductDetailNavbarComponent,
    RaterComponentComponent,
    FeaturedProductsComponent,
    CartProductComponent,
    BreadcrumbsComponent,
    FilterComponent,
    FavoriteProductComponent
  ],
  imports: [
    CommonModule,
    ServicesModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSliderModule,
    RouterModule
  ],
  exports: [
    AlertComponent,
    FooterComponent,
    LoaderComponent,
    DrawerComponent,
    ProductComponent,
    HeaderComponent,
    CartProductComponent,
    ProductDetailCardComponent,
    ProductDetailNavbarComponent,
    RaterComponentComponent,
    FeaturedProductsComponent,
    BreadcrumbsComponent,
    RouterModule,
    FilterComponent,
    FavoriteProductComponent
  ]
})
export class SharedModule {}
