import { BaseService } from './../../core/base/base.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Login } from 'src/services/classes/models/login';
import { Signup } from 'src/services/classes/models/signup';
import { VerificationLink } from 'src/services/classes/models/verification-link';

const routes = {
  login: 'auth/login',
  logout: 'auth/logout',
  signup: 'auth/signup',
  forgotPassword: 'auth/recover',
  resetPassword: 'auth/reset/password',
  changePassword: 'auth/update/password',
  resendVerificationLink: 'auth/email/resend-verification',
  confirmEmail: 'auth/email/verification',
};

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseService<any> {
  constructor(http: HttpClient) {
    super(http);
  }

  login(payload: Login): Observable<any> {
    return this.sendPost(this.baseUrl(routes.login), payload);
  }

  signUp(payload: Signup): Observable<any> {
    return this.sendPost(this.baseUrl(routes.signup), payload);
  }

  resendVerificationLink(payload: VerificationLink): Observable<any> {
    return this.sendPost(this.baseUrl(routes.resendVerificationLink), payload);
  }

  confirmEmailAddressAfterRegistration(token: string): Observable<any> {
    return this.sendGet(this.baseUrl(`${routes.confirmEmail}/${token}`));
  }

  forgotPassword(payload: VerificationLink): Observable<any> {
    return this.sendPost(this.baseUrl(routes.forgotPassword), payload);
  }

  changePassword(payload: any): Observable<any> {
    return this.sendPost(this.baseUrl(routes.changePassword), payload);
  }

  resetPassword(payload: any): Observable<any> {
    return this.sendPost(this.baseUrl(routes.resetPassword), payload);
  }

  logout(): Observable<any> {
    return this.sendGet(this.baseUrl(routes.logout));
  }
}
