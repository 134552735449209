import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rater-component',
  templateUrl: './rater-component.component.html',
  styleUrls: ['./rater-component.component.scss']
})
export class RaterComponentComponent implements OnInit {
  public ratings = [
    {
      stars: 1
    },
    {
      stars: 2
    },
    {
      stars: 3
    },
    {
      stars: 4
    },
    {
      stars: 5
    }
  ];
  public disabled: boolean;
  public ratingText: string;
  public _value: number;

  constructor() {}

  ngOnInit() {}

  onChanged: any = () => {};
  onTouched: any = () => {};

  writeValue(val) {
    this._value = val;
  }

  registerOnChange(fn: any) {
    this.onChanged = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  setRating(star: any) {
    if (!this.disabled) {
      this._value = star.stars;
      // console.log(this._value, 'val');

      this.onChanged(star.stars);
      this.onTouched();
    }
  }
}
