enum LogLevel {
  Off = 0,
  Error,
  Warning,
  Info,
  Debug,
}

export const environment = {
  production: false,
  productLogoLarge: 'assets/images/logo-dark.svg',
  serverUrl:
  'https://prlerp.com/peresianas-BE/public/api/v1/',
  // serverUrl:
  //   'https://www.peresiana-ecomm-backend.nigeriasbsc.com/public/api/v1/',
  flwPubKey: 'FLWPUBK_TEST-daf77b348cd99161b3110384d16d9b38-X',
  paystackPubKey: 'pk_test_eb2d1907b3523dc5d7caf6155da23cbf0f7b2026',
  logLevel: LogLevel.Debug,
};
