<aside class="drawer drawer-default">
  <div class="drawer-header">
    <div class="drawer-header__title">
      <h4 class="mb-0 p-0 font-weight-medium">{{ headerTitle }}</h4>
      <button class="btn mb-0 p-0" (click)="closeDrawer()">
        <img src="assets/icons/drawer-close-btn.svg" alt="close" />
      </button>
    </div>
  </div>
  <div class="drawer-body">
    <ng-content select="[drawerBody]"></ng-content>
  </div>
</aside>
<div class="backdrop"></div>

<!-- Sample usage -->

<!-- The body content of the drawer needs to go into the template with the name "drawerBody"-->
<!-- Don't forget to set the boolean value to open the drawer -->
<!-- You also need to tap into the closeButtonClicked event to close the drawer when the close button is clicked -->
<!-- Sample usage also exists on "orders.component.html" -->

<!-- <app-drawer
    *ngIf="isDrawerOpen"
    [headerTitle]="'Sample Header'"
    (closeButtonClicked)="closeDrawer($event)"
  >
    <div drawerBody>
      <p>
        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Debitis eaque
        recusandae deleniti eligendi nam repellat perspiciatis necessitatibus
        cumque magni, labore facere explicabo libero molestiae soluta aperiam unde
        quo quaerat nesciunt rem at minima officiis impedit asperiores accusamus.
        Sint eveniet minus delectus sapiente ipsa hic, vel cum aliquid nam totam
        consequatur!
      </p>
      <p>
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Excepturi
        ducimus cumque reiciendis eligendi inventore similique praesentium? Odit
        quis iusto beatae doloribus quia odio porro fuga cupiditate est dolores,
        vero aliquid soluta iste assumenda praesentium nihil pariatur quos
        corrupti quaerat consequatur.
      </p>
    </div>
  </app-drawer> -->

<!-- ToDos -->
<!-- Extra styling needed for animations on close and open -->
<!-- Responsiveness -->
<!-- Backdrop when the drawer is open -->

<!-- Anybody can contribute! -->
