<div class="row">
  <div class="col-12">
    <section class="d-none d-md-block">
      <div class="breadcrumbs" *ngIf="!withoutLinks">
        <div class="row">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb ml-md-0 ml-3">
              <li class="breadcrumb-item">
                <a routerLink="/">
                  <img src="assets/icons/home.svg" alt="home-icon" />
                </a>
              </li>
              <li
                *ngIf="breadcrumbItems?.categoryDetails"
                routerLink="/category/{{
                  breadcrumbItems?.categoryDetails?.id
                }}"
                (click)="saveDetails(breadcrumbItems?.categoryDetails)"
                class="breadcrumb-item"
                [ngClass]="
                  objectKeys(breadcrumbItems)?.length === 1 ? 'active' : ''
                "
                aria-current="page"
              >
                {{ breadcrumbItems?.categoryDetails?.cat_name }}
              </li>
              <li
                *ngIf="breadcrumbItems?.subCategoryDetails"
                routerLink="/sub-category/{{
                  breadcrumbItems?.subCategoryDetails.id
                }}"
                (click)="
                  saveDetailsSubCategory(
                    breadcrumbItems?.subCategoryDetails,
                    breadcrumbItems?.categoryDetails
                  )
                "
                class="breadcrumb-item"
                [ngClass]="{
                  active:
                    !breadcrumbItems?.childCategoryDetails &&
                    !breadcrumbItems?.productInfo
                }"
                aria-current="page"
              >
                {{ breadcrumbItems?.subCategoryDetails?.cat_name }}
              </li>
              <li
                *ngIf="breadcrumbItems?.childCategoryDetails"
                routerLink="/child-category/{{
                  breadcrumbItems?.childCategoryDetails.id
                }}"
                (click)="
                  saveDetailsChild(
                    breadcrumbItems?.childCategoryDetails,
                    breadcrumbItems?.subCategoryDetails,
                    breadcrumbItems?.categoryDetails
                  )
                "
                [ngClass]="{ active: !breadcrumbItems?.productInfo }"
                class="breadcrumb-item"
                aria-current="page"
              >
                {{ breadcrumbItems?.childCategoryDetails?.cat_name }}
              </li>

              <li
                *ngIf="breadcrumbItems?.productInfo"
                class="breadcrumb-item active"
                aria-current="page"
              >
                {{ breadcrumbItems?.productInfo?.cat_name }}
              </li>
            </ol>
          </nav>
        </div>
      </div>

      <!-- When you just need a breadcrumb that doesn't lead/link to anywhere -->
      <div class="breadcrumbs" *ngIf="withoutLinks">
        <div class="row">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb ml-md-0 ml-3">
              <li class="breadcrumb-item">
                <a routerLink="/">
                  <img src="assets/icons/home.svg" alt="home-icon" />
                </a>
              </li>
              <li
                *ngIf="breadcrumbItems?.categoryDetails"
                class="breadcrumb-item"
                [ngClass]="
                  objectKeys(breadcrumbItems)?.length === 1 ? 'active' : ''
                "
                aria-current="page"
              >
                {{ breadcrumbItems?.categoryDetails?.cat_name }}
              </li>
              <li
                *ngIf="breadcrumbItems?.subCategoryDetails"
                class="breadcrumb-item"
                [ngClass]="{
                  active:
                    !breadcrumbItems?.childCategoryDetails &&
                    !breadcrumbItems?.productInfo
                }"
                aria-current="page"
              >
                {{ breadcrumbItems?.subCategoryDetails?.cat_name }}
              </li>
              <li
                *ngIf="breadcrumbItems?.childCategoryDetails"
                [ngClass]="{ active: !breadcrumbItems?.productInfo }"
                class="breadcrumb-item"
                aria-current="page"
              >
                {{ breadcrumbItems?.childCategoryDetails?.cat_name }}
              </li>

              <li
                *ngIf="breadcrumbItems?.productInfo"
                class="breadcrumb-item active"
                aria-current="page"
              >
                {{ breadcrumbItems?.productInfo?.cat_name }}
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </section>
  </div>
</div>
