<div class="card d-flex align-items-center flex-row border-0">
  <div class="w-30">
    <img
      *ngIf="product?.image"
      [src]="product?.image"
      [alt]="product?.product_name"
      width="120"
      height="120"
    />
    <img
      *ngIf="!product?.image"
      src="assets/images/perisiana.png"
      [alt]="product?.product_name"
      width="120"
      height="120"
    />
  </div>
  <div class="mx-4 w-60">
    <p class="mb-2 font-weight-medium">
      {{ product?.product_name }}
    </p>
    <div class="mb-2 d-flex justify-content-between align-items-center">
      <p class="mb-0 font-weight-medium">
        {{ product?.unit_price | currency: '₦' }}
      </p>
      <div class="d-flex align-items-center">
        <img src="assets/icons/rating-star.svg" alt="rating-star" /> &nbsp;
        <span>{{ product?.ratings }}</span>
      </div>
    </div>
    <span *ngIf="![1, true].includes(product?.in_stock)" class="out-stock mb-0">
      Out of Stock
    </span>
  </div>
  <div class="w-10 d-flex justify-content-end">
    <img
      (click)="removeItemFromWishlist(product)"
      class="cancel"
      src="assets/icons/close-icn.svg"
      alt="close-icn"
    />
  </div>
</div>
