import { Logger } from 'src/services/core/logger/logger.service';
import { BreadcrumbDetails } from './../../../services/classes/models/breadcrumb';
import { Component, Input, OnInit } from '@angular/core';

const log = new Logger('BC');
@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
  @Input() breadcrumbItems: BreadcrumbDetails;
  @Input() withoutLinks = false;
  objectKeys = Object.keys;

  /**
   * Structure for the breadcrumb items
   *  breadcrumbItems = {
   *  categoryDetails: {
   *    id: number,
   *     cat_name: string
   *   },
   *   subCategoryDetails: {
   *     id: number,
   *     cat_name: string
   *   },
   *   childCategoryDetails: {
   *     id: number,
   *     cat_name: string
   *   },
   *   productInfo: {
   *     id: number,
   *     cat_name: string
   *   },
   * }
   */

  constructor() {}

  ngOnInit() {}

  saveDetails(categoryDetails: any) {

    sessionStorage.setItem('details', JSON.stringify(categoryDetails));
  }
  saveDetailsChild(business_unit,division,categories){
    const details = {
      cat_id:categories.id,
      cat_name: categories.cat_name,
      division_id: division.id,
      division_name: division.cat_name,
      business_unit_id: business_unit.id,
      business_unit_name: business_unit.cat_name
    }
    sessionStorage.setItem('details', JSON.stringify(details));
    
  }
  saveDetailsSubCategory(division, categories){
    const details = {
      cat_id:categories.id,
      cat_name: categories.cat_name,
      division_id: division.id,
      division_name: division.cat_name,
    }

    sessionStorage.setItem('details', JSON.stringify(details));

  }
}
